import Breadcrumbs from 'components/common/breadcrumbs';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyleExtendsSection } from './Claim.styled';
import ProgressBar from 'components/common/progress-bar';
import { Field, FormField, FormGroup } from 'components/common/exp-form';
import { FormFooter, FormInput, FormPanel } from 'components/layouts/page-layout';
import { TextTitle } from 'components/common/styled-components-common';
import { Else, If, Then } from 'components/common/if-condition';
import { EXPCollapseComplete } from 'components/common/exp-collapse';
import ClaimHistory from 'modules/conditional/claim-history';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import { emailPattern, firstNameLastNamePattern, phoneNumberPattern } from 'constants/regex-pattern';
import useServiceConfig from 'hooks/useServiceConfig';
import Button from 'components/common/button';
import usePageContext from 'hooks/usePageContext';
import React, { useEffect, useRef, useState } from 'react';
import ClaimVoucherServiceReceive from 'components/elements/voucher-service-receive';
import ServiceOnboarding from 'components/elements/service-onboarding';
import PopupConsentWithTheme from 'modules/conditional/popup-consent-with-theme';
import ClaimCancelTask from 'modules/conditional/claim-cancel-task';
import EXPPopupJs from 'components/common/exp-popupjs';
import { cryptojs, getFirstNameLastName, getFullName, paramsjs } from 'helpers';
import { POST } from 'api';
import useAuth from 'hooks/useAuth';
import { browserName } from 'react-device-detect';
import { checkServiceHoursNew, getSelectCoverage } from 'api/api-often';
import { DEFAULT_PHARMCARE_PROGRAM, DEFAULT_VOUCHER_CODE } from 'constants/service-config';
import moment from 'moment';
import useQueryParams from 'hooks/useQueryParams';
import { ClaimCheckCoveragePayload, TaskAlreadyDetail } from 'types';

interface FormData {
    fullName: string;
    phoneNumber: string;
    userEmail: string;
    userPidType: string;
    userPid: string;
    dateOfBirth: string;
}

interface VoucherDetailData {
    code: string | null;
    defaultCode: string | null;
    amount: number;
    serviceCost: number;
    serviceFee: number;
    hasChange: boolean;
    isValid: boolean;
    coverage: any;
    detail: any;
}

function ClaimStartV2() {
    const { t, i18n } = useTranslation(['claim']);
    const navigate = useNavigate();
    const location = useLocation();
    const { query: params, queryToParams } = useQueryParams();
    const theme = useThemeWithPartner();
    const serviceConfig = useServiceConfig();
    const { auth, profile, getProfile, updateProfile } = useAuth();
    const { setPageLoaded } = usePageContext();
    const buttonRef = useRef<string | undefined>();
    //

    const [formData, setFormData] = useState<FormData>({
        fullName: '',
        userEmail: '',
        phoneNumber: '',
        userPidType: '',
        userPid: '',
        dateOfBirth: '',
    });
    const [voucherDetail, setVoucherDetail] = useState<VoucherDetailData>({
        code: null,
        amount: 0,
        serviceCost: 0,
        serviceFee: 0,
        hasChange: false,
        isValid: true,
        coverage: {},
        detail: {},
        defaultCode: '',
    });
    const [taskAlreadyDetail, setTaskAlreadyDetail] = useState<TaskAlreadyDetail>({
        taskId: '',
        processCode: 0,
    });
    const [showVoucherInput, setShowVoucherInput] = useState<boolean>(true);
    const [showVoucherCode, setShowVoucherCode] = useState<boolean>(false);
    const [showBilling, setShowBilling] = useState<boolean>(false);
    const [isBilling, setIsBilling] = useState<boolean>(false);
    const [showButtonDouble, setShowButtonDouble] = useState<boolean>(true);
    const [pharmacyCode, setPharmacyCode] = useState<string>('');
    const [showServiceReceived, setShowServiceReceived] = useState<boolean>(false);
    const [showServiceOnboarding, setShowServiceOnboarding] = useState<boolean>(false);
    const [showConsentPopup, setShowConsentPopup] = useState<boolean>(false);
    const [isAcceptTc, setIsAcceptTc] = useState<'Y' | 'N' | ''>('N');
    const [isFakePid, setIsFakePid] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [isSendingGoToClaim, setIsSendingGoToClaim] = useState<boolean>(false);
    const [showPopupCancelTask, setShowPopupCancelTask] = useState<boolean>(false);

    //
    const getProcessCode = (taskStatusCode: number) => {
        if (taskStatusCode === 0) return 4;

        if (taskStatusCode === 10) return 14;

        if (taskStatusCode === 20) return 24;

        return 0;
    };
    const onSelectCoverage = async (coverageItem: any, userPid: string | null, phrExistFlag: any, skipPhr?: number, createdAt?: string) => {
        try {
            const res = await getSelectCoverage({
                userPid: userPid,
                pharmacyCode: pharmacyCode,
                pvt: params?.pvt as string,
                hspCode: params?.hspCode as string,
                createdAt: createdAt as string,
                skipPhr: skipPhr as number,
                phrExistFlag: phrExistFlag,
                serviceConfig: window.location.pathname.startsWith('/telepharmacy-ltc')
                    ? { ...serviceConfig, name: 'telepharmacyLTC' }
                    : serviceConfig,
                coverageData: coverageItem,
                translation: t,
            });
            await updateProfile();

            setIsSending(false);

            if (res.statusCode === 300) {
                setTaskAlreadyDetail({ ...res.taskAlready });

                EXPPopupJs.alert({
                    'data-test-id': 'popup-warning-have-task',
                    icon: 'warning',
                    title: t('common:warning'),
                    message: res.taskAlready.rejectReason,
                    classNames: { message: 'font-detail2' },
                    buttons: onCheckBTNCancel({ ...res.taskAlready, taskStatusCode: res.statusCode }) as any[],
                });
            } else {
                navigate(res.redirectUrl);
            }
        } catch (error) {
            console.error('/claim/selectCoverage error', error);
            setIsSending(false);
        }
    };

    const callAction = (startAction: () => void, close: () => void, data: any = {}) => {
        startAction();
        navigate(data.ctaRedirectUrl + '?task=' + data.taskId + '&flag=' + data.phrExistFlag);

        close();
    };

    const onCheckBTNCancel = (data: TaskAlreadyDetail) => {
        if ((data.taskStatusCode as number) < 10 && data.appointmentNo === null && data.pharmacyServiceId) {
            return [
                {
                    label: t('claim:cancel_task.complete_ongoing_service'),
                    variant: 'primary',
                    action: ({ startAction, close }) => callAction(startAction, close, data),
                },
                {
                    label: t('claim:cancel_task.cancel_ongoing_service'),
                    className: 'btn-underline',
                    action: () => setShowPopupCancelTask(true),
                },
            ];
        } else {
            return [
                {
                    label: t('claim:cancel_task.complete_ongoing_service'),
                    variant: 'primary',
                    action: ({ startAction, close }) => callAction(startAction, close, data),
                },
            ];
        }
    };
    const getBypassPid: () => Promise<{ pid: string | null; dateOfBirth: string | null }> = () => {
        return new Promise(async (resolve) => {
            try {
                const res = await POST('/user/bypassPid', {
                    pid: null,
                    pidType: null,
                    dateOfBirth: null,
                });
                resolve({
                    pid: res.userProfile.userPid,
                    dateOfBirth: res.userProfile.dateOfBirth,
                });
            } catch (error) {
                console.log('/user/bypassPid => error: ', error);
                resolve({
                    pid: null,
                    dateOfBirth: null,
                });
            }
        });
    };
    const getCoverage = async (payload: ClaimCheckCoveragePayload) => {
        const nextParams = queryToParams({
            pharmacyCode: pharmacyCode,
            pvt: params.pvt,
            hspCode: params.hspCode,
            billing: params.billing,
            privilegeCardNo: params.mtlCardNo,
            voucher: payload.voucherCode,
            isBilling: isBilling ? 'true' : 'false',
            policyNumber: payload.policyNumber,
            ltcFlag: window.location.pathname.startsWith('/telepharmacy-ltc') ? '1' : '0',
        });
        const claimPidPath = serviceConfig.servicePath + '/claim/pid' + nextParams;

        if (buttonRef.current === 'submitdefault') {
            setIsSending(true);
        }
        if (buttonRef.current === 'submitToClaim') {
            setIsSendingGoToClaim(true);
        }

        try {
            const res = await POST('/claim/checkCoverage', payload);
            if (res.statusCode === 200) {
                let pid = payload.data.userPid;

                if (isFakePid && res.claimModeConfig?.skipPid === 1) {
                    const bypassPid = await getBypassPid();
                    pid = bypassPid.pid as string;
                }
                await updateProfile();

                const amountZero = res.data.filter((e: { amount: number }) => e.amount < 1);
                const isAllAmountZero = amountZero.length === res.data.length;

                if (buttonRef.current === 'submitdefault' && theme.name === 'default' && !isFakePid) {
                    const coverageWithVoucher = res.data.find((f: { voucherCode: any }) => f.voucherCode === payload.voucherCode);
                    if (coverageWithVoucher) {
                        onSelectCoverage(coverageWithVoucher, pid, res.phrExistFlag, res.claimModeConfig.skipPhr, payload.data.createdAt);
                    } else {
                        onSelectCoverage(res.data[0], pid, res.phrExistFlag, res.claimModeConfig.skipPhr, payload.data.createdAt);
                    }
                }
                // have one coverage
                else if (buttonRef.current === 'submitdefault' && res.data.length === 1 && res.claimModeConfig?.skipPid === 1) {
                    onSelectCoverage(res.data[0], pid, res.phrExistFlag, res.claimModeConfig.skipPhr, payload.data.createdAt);
                } else if (buttonRef.current === 'submitdefault' && voucherDetail.coverage.companyCode === '99') {
                    onSelectCoverage(res.data[0], payload.data.userPid, res.phrExistFlag);
                } else if (
                    buttonRef.current === 'submitToClaim' ||
                    (res.claimModeConfig?.skipPid !== 1 &&
                        (isFakePid ||
                            (theme.name === 'rabbit' && isAllAmountZero) ||
                            (isBilling && theme.name !== 'workplaze') ||
                            (theme.name === 'default_sunday' && serviceConfig.name !== 'telepharmacy') ||
                            ['mtl', 'default_oceanopd'].some((f) => f === theme.name)))
                ) {
                    navigate(claimPidPath);
                } else if (buttonRef.current === 'submitdefault' && res.data.length === 1) {
                    onSelectCoverage(res.data[0], payload.data.userPid, res.phrExistFlag);
                } else {
                    const nextParams = queryToParams({
                        pvt: params.pvt,
                        voucherCode: payload.voucherCode,
                        pharmacyCode: pharmacyCode,
                        policyNumber: payload.policyNumber,
                        privilegeCardNo: params.mtlCardNo,
                        ltcFlag: window.location.pathname.startsWith('/telepharmacy-ltc') ? '1' : '0',
                    });

                    navigate(serviceConfig.servicePath + '/claim/select/' + nextParams);
                }
            } else if (res.statusCode === 300) {
                setIsSending(false);
                setIsSendingGoToClaim(false);

                const taskAlready = {
                    taskId: res.task.taskId,
                    processCode: getProcessCode(res.task.taskStatusCode),
                    appointmentNo: res.task.appointmentNo,
                    pharmacyServiceId: res.task.pharmacyServiceId,
                    taskStatusCode: res.task.taskStatusCode,
                    ctaRedirectUrl: res.task.ctaRedirectUrl,
                    rejectReason: res.rejectReason,
                    phrExistFlag: res.task.phrExistFlag,
                };

                setTaskAlreadyDetail({ ...taskAlready });

                EXPPopupJs.alert({
                    'data-test-id': 'popup-warning-have-task',
                    icon: 'warning',
                    title: t('common:warning'),
                    message: res.rejectReason,
                    classNames: { message: 'font-detail2' },
                    buttons: onCheckBTNCancel(taskAlready) as any[],
                });
            }
        } catch (error: any) {
            console.error('/claim/checkCoverage error', error);

            setIsSending(false);
            setIsSendingGoToClaim(false);

            if (
                (isFakePid || ['mtl', 'rabbit', 'default_oceanopd', 'default_sunday'].some((s) => theme.name === s)) &&
                error.statusCode === 404
            ) {
                // param voucher || default partner voucher || default voucher
                // navigate(claimPidPath);
                EXPPopupJs.simple('warning', t('common:error'), 'ไม่พบสิทธิ์ของท่าน หรือ สิทธิ์ของท่านได้ถูกใช้ไปแล้ว');
            } else if (error.statusCode === 404 && theme.name === 'central20') {
                EXPPopupJs.simple('warning', t('common:error'), 'ไม่พบสิทธิ์ของท่าน หรือ สิทธิ์ของท่านได้ถูกใช้ไปแล้ว');
            } else if (error.redirectUrl && error.statusCode === 404) {
                navigate(error.redirectUrl);
            } else if (error.isNoPopup) {
                EXPPopupJs.simple('warning', t('common:error'), error.rejectReason);
            }
        }
    };
    const onSubmit = async () => {
        const paramDate = params.timestamp || params.issuedate;
        let formDataTemp = { ...formData };
        // # check service hours
        const serviceHours = checkServiceHoursNew(serviceConfig.name, theme.name);
        if (serviceHours.isOutOfServiceTime && params.pvt !== 'true') {
            EXPPopupJs.simple(
                'warning',
                t(`claim:out_of_service_hours.out_of_service_hours_withSeviceOrigin.${serviceConfig.serviceOrigin}`),
                t('claim:out_of_service_hours.please_visit_again_between', {
                    between: `${serviceHours.open}-${serviceHours.close}`,
                })
            );
        } else if (
            (profile.userXPartner?.partnerCompanyCode === '20' || theme.name === 'central20') &&
            (!params.ref || !paramDate || paramDate !== moment().format('YYYYMMDD'))
        ) {
            EXPPopupJs.simple(
                'warning',
                t('common:error'),
                'ลิ้งค์ของท่านหมดอายุหรือไม่ถูกต้อง กรุณาเข้ารับบริการผ่านลิ้งค์จากอีเมลที่ได้รับผ่าน CNEXT'
            );
        } else {
            // check voucher again if voucherDetail hasChange
            let voucherIsValid = voucherDetail.isValid;
            if (voucherDetail.hasChange && voucherDetail.code) {
                // set loading button
                if (buttonRef.current === 'submitdefault') {
                    setIsSending(true);
                }
                if (buttonRef.current === 'submitToClaim') {
                    setIsSendingGoToClaim(true);
                }

                const voucher = await getVoucher(voucherDetail.code as string);
                voucherIsValid = voucher.isValid;
                // bypass
                if (voucher.detail?.companyCode === '99' && (formDataTemp.userPid === null || isFakePid)) {
                    const { pid, dateOfBirth } = await getBypassPid();

                    formDataTemp.dateOfBirth = dateOfBirth as string;
                    formDataTemp.userPidType = '02';
                    formDataTemp.userPid = pid as string;
                }

                setVoucherDetail((prevState) => ({
                    ...prevState,
                    isValid: voucher.isValid,
                    amount: voucher.detail.amount,
                    hasChange: false,
                    coverage: voucher.detail,
                }));

                setIsSending(false);
                setIsSendingGoToClaim(false);
            }
            // ***proces will stop if voucherIsValid = false
            // companyCode = profile.userXPartner.partnerCompanyCode
            if (voucherIsValid) {
                const { firstName, lastName } = getFirstNameLastName(formDataTemp.fullName);
                let companyCode: string | null = null;
                let apiCallType: string | undefined = undefined;
                let policyNumber: string | null = null;
                let voucherCode: string | null = null;
                let userPid: string | null = formDataTemp.userPid;
                // set voucherCode
                if (profile?.userXPartner && profile?.userXPartner?.partnerCompanyCode === '25') {
                    companyCode = voucherDetail?.detail?.companyCode;
                    voucherCode = voucherDetail?.detail?.voucherCode;
                } else if (theme.name === 'central20') {
                    voucherCode = null;
                    policyNumber = `20-E${params.emp}-R${params.ref}`;
                } else if (theme.name === 'workplaze') {
                    const userProfile = await getProfile();
                    //
                    userPid = userProfile.userPid;
                    apiCallType = 'DATAON_OPD_API';
                    voucherCode = null;
                    companyCode = '34';
                    policyNumber = `34-A${userProfile.userSsoProfile?.accName}-C${userProfile.userSsoProfile?.userCompanyCode}-E${userProfile.userSsoProfile?.employeeId}`;
                } else if (theme.name === 'humatrix') {
                    voucherCode = 'humatrix';
                    policyNumber = params.policyNumber;
                } else if (theme.name === 'default_sunday' && serviceConfig.name === 'telepharmacy') {
                    companyCode = voucherDetail?.detail?.companyCode ? voucherDetail?.detail?.companyCode : '00';
                    voucherCode = voucherDetail?.detail?.voucherCode ? voucherDetail?.detail?.voucherCode : 'TLP';
                } else if (voucherDetail.code) {
                    voucherCode = voucherDetail.code;
                } else {
                    // default voucher
                    voucherCode =
                        profile.defaultCoverage?.voucherCode ||
                        profile.userXPartner?.partnerDefaultVoucherCode ||
                        DEFAULT_VOUCHER_CODE[serviceConfig.name] ||
                        null;
                }
                //
                const payload: ClaimCheckCoveragePayload = {
                    companyCode: companyCode,
                    policyNumber: policyNumber,
                    apiCallType: apiCallType,
                    voucherType: null,
                    voucherCode: voucherCode,
                    pharmcareProgram: DEFAULT_PHARMCARE_PROGRAM[serviceConfig.name],
                    taskCommunicationType: serviceConfig.communicationType,
                    validatePid: 0,
                    data: {
                        firstName: firstName,
                        lastName: lastName,
                        userPidType: formDataTemp.userPidType,
                        userPid: userPid,
                        userEmail: formDataTemp.userEmail,
                        dateOfBirth: formDataTemp.dateOfBirth,
                        phoneNumber: formDataTemp.phoneNumber,
                        createdAt: profile.createdAt as string,
                    },
                };

                getCoverage(payload);
            }
        }
    };
    const onChangeText = (event: any) => {
        event.persist();
        setFormData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
    };
    const onChangeCheckboxIsBilling = (event: any) => {
        event?.persist();
        setIsBilling(event.target.checked);
        setShowVoucherInput(!event.target.checked);
        setVoucherDetail((prevState) => ({
            ...prevState,
            code: event.target.checked ? params.billing : params.voucher,
            hasChange: true,
            isValid: true,
            amount: 0,
        }));
    };
    const onChangeVoucher = (event: any) => {
        event?.persist();
        setVoucherDetail((prevState) => ({
            ...prevState,
            code: event?.target?.value ?? '',
            amount: 0,
            hasChange: true,
            isValid: true,
        }));
        setShowVoucherCode(false);
    };
    const onContinueClaim = () => {
        if (isAcceptTc === 'N' || isAcceptTc === '') {
            setShowServiceReceived(false);
            setTimeout(() => {
                setShowConsentPopup(true);
            }, 600);
        } else {
            setShowServiceReceived(false);
        }
    };
    const onContinueToPopupConsent = () => {
        if (isAcceptTc === 'N' || isAcceptTc === '') {
            setShowServiceOnboarding(false);
            setTimeout(() => {
                setShowConsentPopup(true);
            }, 600);
        } else {
            setShowServiceOnboarding(false);
        }
    };
    const onSubmitAcceptTc = async (data: any = {}) => {
        const consent = [data.c1 || null, data.c2 || null, data.c3 || null, data.c4 || null];

        try {
            await POST('/user/accept-tc', {
                pid: formData.userPid,
                tcFlag: 1,
                consent: consent,
            });

            await updateProfile();

            setTimeout(() => {
                setShowConsentPopup(false);
            }, 500);
        } catch (error) {
            console.error('user/accept-tc ~ error', error);
        }
    };
    const onClosePopupAct = () => {
        if (profile.userType === 'SSO') {
            //change logic to previous page
            navigate(-1);
        } else {
            //theme !== defualt --> plugin auto
            navigate('/home');
        }
    };

    const getVoucher: (voucherCode: string | null) => Promise<{ code: string | null; isValid: boolean; detail: any }> = (voucherCode) => {
        return new Promise(async (resolve) => {
            if (!voucherCode) {
                resolve({
                    code: voucherCode,
                    isValid: false,
                    detail: {},
                });
            } else {
                try {
                    const res = await POST('/claim/checkVoucherCode', {
                        voucherCode: voucherCode,
                        pharmcareProgram: DEFAULT_PHARMCARE_PROGRAM[serviceConfig.name],
                    });

                    resolve({
                        code: voucherCode,
                        isValid: true,
                        detail: res.data,
                    });
                } catch (error: any) {
                    console.info('claim/checkVoucherCode error', error);
                    if (error.isNoPopup) {
                        EXPPopupJs.simple('warning', t('common:error'), error.rejectReason);
                    }
                    resolve({
                        code: voucherCode,
                        isValid: false,
                        detail: {},
                    });
                }
            }
        });
    };

    const userSSOCallLineAuthen = async (sessionState: string | null = null) => {
        if (process.env.REACT_APP_POPUP_ALERT_THEME_WORKPLAZE === 'true') alert('browserName: ' + browserName);
        try {
            const res = await POST('/user/lineAuthen', {
                sessionId: params?.['liff.state'] || '/home',
                sessionState,
            });

            //
            if (process.env.REACT_APP_POPUP_ALERT_THEME_WORKPLAZE === 'true') alert('lineAuthen: successful');
            const url = res.lineAuthenStateUrl || res.lineAuthenUrl;

            if (browserName === 'WebKit' || browserName === 'Chrome WebView') {
                if (process.env.REACT_APP_POPUP_ALERT_THEME_WORKPLAZE === 'true') alert(url);
                if (window.gotoExternalAppWithWPFlutter) {
                    window.gotoExternalAppWithWPFlutter(url);
                } else {
                    if (process.env.REACT_APP_POPUP_ALERT_THEME_WORKPLAZE === 'true') alert('not found function gotoExternalApp');
                    window.location.replace(url);
                }
            } else {
                window.location.replace(url);
            }
        } catch (error) {
            setPageLoaded(true);
            console.error('lineAuthen ~ error', error);
        }
    };
    const getInitialData = async () => {
        try {
            // # check service hours, ***only show, do nothing
            const serviceHours = checkServiceHoursNew(serviceConfig.name, theme.name);
            if (serviceHours.isOutOfServiceTime && params.pvt !== 'true') {
                EXPPopupJs.simple(
                    'warning',
                    t(`claim:out_of_service_hours.out_of_service_hours_withSeviceOrigin.${serviceConfig.serviceOrigin}`),
                    t('claim:out_of_service_hours.please_visit_again_between', {
                        between: `${serviceHours.open}-${serviceHours.close}`,
                    })
                );
            }
            let voucherIsValid = voucherDetail.isValid;
            // check first voucher
            if (params.billing || params.voucher || params['liff.state']) {
                let voucherCode: string | null = null;

                if (params.billing) {
                    voucherCode = params.billing as string;
                } else if (params.voucher) {
                    voucherCode = params.voucher as string;
                } else if (params['liff.state']) {
                    const liffState = paramsjs.query(window.decodeURIComponent(params['liff.state']));
                    voucherCode = liffState.voucher as string;
                }
                const voucher = await getVoucher(voucherCode);
                voucherIsValid = voucher.isValid;

                // has voucher, setVoucherDetail
                if (voucher.isValid) {
                    // set data
                    let voucherDetailTemp: VoucherDetailData = { ...voucherDetail, ...voucher };

                    if (serviceConfig.name === 'telepharmacy' && voucher.detail?.pharmacistThresholdAmount < 150) {
                        voucherDetailTemp.serviceCost = 150;
                    }
                    if (theme.name === 'central' || theme.name === 'pandf') {
                        voucherDetailTemp.serviceCost = 0;
                    }
                    if (voucher.detail?.packagingThresholdAmount < 10) {
                        voucherDetailTemp.serviceFee = 55;
                    }

                    setVoucherDetail({ ...voucherDetailTemp });
                }
                // bypass Pid
                if (voucher.isValid && voucher.detail.companyCode === '99' && (profile.userPid === null || profile.fakePidFlag === 1)) {
                    const { pid, dateOfBirth } = await getBypassPid();

                    setFormData((prevState) => ({
                        ...prevState,
                        dateOfBirth: dateOfBirth as string,
                        userPidType: '02',
                        userPid: pid as string,
                    }));
                }

                if (voucher.isValid && (voucher.detail.companyCode === '16' || voucher.detail.companyCode === '21')) {
                    setShowButtonDouble(false);
                }
                if (theme.name === 'betterperiod' || theme.name === 'central20') {
                    setShowVoucherInput(false);
                }
                if (voucher.code) {
                    setShowVoucherCode(true);
                }
            }
            // setPharmacyCode
            if (serviceConfig.name === 'telepharmacy' && theme.name === 'doctorhub') {
                setPharmacyCode('CPBKK0000173');
            } else if (params.pharmacyCode) {
                setPharmacyCode(params.pharmacyCode);
            }
            // setIsBilling
            if (params.billing || profile?.genEcdFlag === 1) {
                setShowVoucherInput(false);
                setIsBilling(true);
                setShowBilling(true);
            }
            // only set this, not has other chenge
            if (profile.fakePidFlag === 1) {
                setIsFakePid(true);
            }
            // setIsAcceptTc
            if (process.env.REACT_APP_RUN_TEST === 'true') {
                setIsAcceptTc('N');
            } else if (profile.acceptTcFlag === 1) {
                setIsAcceptTc('Y');
            }

            const partnerNotServiceReceived = [
                'default',
                'default_humatrix',
                'humatrix',
                'default_oceanopd',
                'default_sunday',
                'default_pharmcareads',
                'betterperiod',
                'doctorhub',
                'workplaze',
                'central20',
                'central30',
                'cpa',
                'livewell',
                'mtl',
                'abbot',
                'bilaxten',
            ].every((e) => theme.name !== e);

            // show onboarding or service receive or consent
            if (
                theme.name === 'doctorhub' ||
                (theme.name === 'central20' && serviceConfig.name === 'tlm') ||
                (theme.name === 'central30' && serviceConfig.name === 'tlm')
            ) {
                setShowServiceOnboarding(true);
            } else if (
                voucherIsValid &&
                partnerNotServiceReceived &&
                serviceConfig.name !== 'mth' &&
                !(serviceConfig.name === 'tlm' && theme.name === 'central') &&
                !(serviceConfig.name === 'telemed' && theme.name === 'yaphrom')
            ) {
                setShowServiceReceived(true);
            } else if (profile.acceptTcFlag === 0) {
                setShowConsentPopup(true);
            }
            // first set formData
            setFormData({
                fullName: getFullName(profile.firstName as string, profile.lastName as string),
                userEmail: profile.userEmail as string,
                phoneNumber: profile.phoneNumber as string,
                dateOfBirth: profile.dateOfBirth as string,
                userPidType: profile.userPidType as string,
                userPid: profile.userPid as string,
            });

            setPageLoaded(true);
        } catch (error) {
            console.error('error: ', error);
            setPageLoaded(true);
        }
    };

    useEffect(() => {
        setPageLoaded(true);

        const init = async () => {
            if (
                (theme.name === 'default_sunday' && serviceConfig.name === 'fulfillment') ||
                (theme.name === 'default_oceanopd' && serviceConfig.name === 'fulfillment')
            ) {
                EXPPopupJs.alert({
                    type: 'warning',
                    icon: 'warning',
                    isClickOutSideClose: false,
                    title: t('common:warning'),
                    message: 'ขออภัยท่านไม่สามารถใช้บริการนี้ได้',
                    classNames: { message: 'font-detail2' },
                    buttons: [
                        {
                            label: t('common:ok'),
                            variant: 'primary',
                            action: ({ startAction, close }) => {
                                startAction();
                                if (theme.name === 'default_sunday') {
                                    navigate('/tlm/claim' + location.search);
                                } else {
                                    navigate('/home' + location.search);
                                }
                                close();
                            },
                        },
                    ],
                });
            } else if (!profile.lineUserId && profile.userType === 'SSO') {
                const dataForEncode = {
                    path: window.location.pathname,
                    params: window.location.search,
                    data: {
                        authorization: auth.accessToken,
                        partner: profile.userXPartner?.partnerCompanyCode,
                        userId: profile.userId,
                        userType: profile.userType,
                        language: i18n?.language?.toUpperCase(),
                    },
                };

                const encode = cryptojs.encode(dataForEncode);
                if (browserName === 'Facebook') {
                    window.open(process.env.REACT_APP_LIFF_REDIRECT + '?to=' + encode);
                    window.location.replace('/');
                } else {
                    userSSOCallLineAuthen(encode);
                }
            } else {
                try {
                    await POST('/user/save-temp-data', {
                        userTempData: {},
                    });

                    getInitialData();
                } catch (error) {
                    console.error('/user/save-temp-data error: ', error);
                }
            }
        };

        if (serviceConfig.readyConfig && theme.name !== '') {
            init();
        }
    }, [serviceConfig, theme]);

    return (
        <>
            <Breadcrumbs
                title={t('claim:breadcrumbs.patient_details')}
                icon={<IconConfigWithTheme pageName='claim' iconName='breadcrumbs' />}
                onBack={() => navigate(-1)}
            />
            <StyleExtendsSection>
                <ProgressBar />
                <FormField onSubmit={onSubmit}>
                    <FormPanel>
                        <FormInput>
                            {theme.name === 'mtl' && (
                                <>
                                    <div className='text-center my-2 text-black-1 fw-500'>
                                        <div>{t('claim:create.carecover_clinic')}</div>
                                        <div>{t('claim:create.license_number')} 10110005667</div>
                                    </div>
                                    <div className='text-center mt-2 mb-3 text-second cursor-pointer  fw-500'>
                                        <u onClick={() => navigate('/tlm/consult/doctor-list' + location.search)}>
                                            {t('claim:create.view_doctor_list')}
                                        </u>
                                    </div>
                                </>
                            )}
                            {theme.name === 'morningmind' && (
                                <div className='text-center mt-2 mb-3 text-black-1 fw-500'>
                                    <div>มอร์นิ่งมายด์คลินิกเฉพาะทางเวชกรรมจิตเวช</div>
                                    <div>{t('claim:create.license_number')} 10102006165</div>
                                </div>
                            )}

                            <TextTitle id='title'>{t('claim:create.please_fill_patient_details')}</TextTitle>
                            {theme.name === 'central20' && params.emp && (
                                <div className='mb-3'>
                                    <small> {t('claim:create.employee_id')}</small>
                                    <div>{params.emp}</div>
                                </div>
                            )}
                            <Field.Control
                                title={t([`claim:create.firstname_lastname.withTheme.${theme.name}`, 'common:profile.firstname_lastname'])}
                                name='fullName'
                                value={formData.fullName || ''}
                                placeholder={t('common:profile.fill_firstname_lastname')}
                                onChange={onChangeText}
                                required
                                pattern={firstNameLastNamePattern}
                                errorMsg={t('common:profile.please_firstname_lastname')}
                            />
                            <Field.Control
                                title={t('common:profile.phone_number')}
                                type='tel'
                                name='phoneNumber'
                                value={formData.phoneNumber || ''}
                                onChange={onChangeText}
                                placeholder={t('common:profile.fill_phone_number')}
                                required
                                pattern={phoneNumberPattern}
                                errorMsg={t('common:profile.please_phone_number')}
                            />
                            {theme.name === 'livewell' && (
                                <Field.Control
                                    title={t('common:profile.email')}
                                    name='userEmail'
                                    value={formData.userEmail || ''}
                                    onChange={onChangeText}
                                    placeholder='ระบุอีเมล'
                                    required
                                    pattern={emailPattern}
                                    errorMsg='อีเมลผิดรูปแบบ'
                                />
                            )}
                            <If condition={showVoucherInput}>
                                <Then>
                                    <EXPCollapseComplete
                                        defaultShow={params.show === 'true'}
                                        title={t('claim:create.fill_voucher_code_if_any')}
                                        className='claim-input-voucher'
                                    >
                                        <Field.Control
                                            style={{ backgroundColor: 'var(--claim-input-voucher-background-color)' }}
                                            name='voucher_code'
                                            value={showVoucherCode ? '' : voucherDetail.code ?? ''}
                                            placeholder={t('claim:create.fill_voucher_code_if_any')}
                                            onChange={onChangeVoucher}
                                        >
                                            {!voucherDetail.hasChange && !voucherDetail.isValid && (
                                                <small className='text-red-1'>{t('claim:create.no_voucher_code_found')}</small>
                                            )}
                                        </Field.Control>
                                    </EXPCollapseComplete>
                                </Then>
                            </If>
                            <If condition={showBilling}>
                                <Then>
                                    <FormGroup className='input-checkbox-type1'>
                                        <Field.Checkbox
                                            label={t('claim:create.would_like_reimburse_service')}
                                            name='isBilling'
                                            value='01'
                                            checked={isBilling}
                                            onChange={onChangeCheckboxIsBilling}
                                        />
                                    </FormGroup>
                                </Then>
                            </If>
                            {!(['bilaxten'].some((f) => f === theme.name) && params?.hspCode) && (
                                <ClaimHistory serviceName={serviceConfig.name} onSelected={(e) => setPharmacyCode(e)} />
                            )}
                        </FormInput>
                        <FormFooter requireSticky={true}>
                            <If
                                condition={
                                    showButtonDouble &&
                                    serviceConfig.serviceOrigin !== 'mth' &&
                                    serviceConfig.serviceOrigin !== 'tlm-ncd' &&
                                    serviceConfig.serviceOrigin !== 'telemed' &&
                                    serviceConfig.serviceOrigin !== 'telepharmacy' &&
                                    serviceConfig.serviceOrigin !== 'nutritionist' &&
                                    serviceConfig.serviceOrigin !== 'physiotherapy' &&
                                    serviceConfig.serviceOrigin !== 'fulfillment' &&
                                    theme.name === 'default'
                                }
                            >
                                <Then>
                                    <Button
                                        variant='primary'
                                        loading={isSending}
                                        disabled={
                                            isSendingGoToClaim ||
                                            (!formData.fullName && !formData.phoneNumber) ||
                                            profile.userId === 0 ||
                                            profile.status === 0
                                        }
                                        data-test-id='submitSelfPay'
                                        className='theme-btn mx-auto d-block'
                                        id='submitdefault'
                                        onClick={() => (buttonRef.current = 'submitdefault')}
                                    >
                                        {t('common:self_pay')}
                                    </Button>
                                    <Button
                                        variant='primary'
                                        loading={isSendingGoToClaim}
                                        disabled={
                                            isSending ||
                                            (!formData.fullName && !formData.phoneNumber) ||
                                            profile.userId === 0 ||
                                            profile.status === 0
                                        }
                                        data-test-id='submitInsuranceHolder'
                                        className='theme-btn mx-auto d-block'
                                        id='submitToClaim'
                                        onClick={() => (buttonRef.current = 'submitToClaim')}
                                    >
                                        <span>{t('common:insurance_holder')}</span>
                                        <div className='insurance-btn'>
                                            {['tlm', 'tlmNcd'].some((f) => f === serviceConfig.name) && (
                                                <img src='/images/claim/logo-sunday.png' alt='logo-sunday' />
                                            )}
                                            {serviceConfig.name === 'telepharmacy' && (
                                                <img src='/images/claim/logo-ocean.jpg' alt='logo-ocean' />
                                            )}
                                        </div>
                                    </Button>
                                </Then>
                                <Else>
                                    <Button
                                        variant='primary'
                                        loading={isSending}
                                        disabled={
                                            (!formData.fullName && !formData.phoneNumber) || profile.userId === 0 || profile.status === 0
                                        }
                                        data-test-id='submit'
                                        className='theme-btn mx-auto d-block'
                                        id='submitdefault'
                                        onClick={() => (buttonRef.current = 'submitdefault')}
                                    >
                                        {t('common:confirm')}
                                    </Button>
                                </Else>
                            </If>
                        </FormFooter>
                    </FormPanel>
                </FormField>
            </StyleExtendsSection>
            {/* ------- popup service receive first time ------- */}
            <ClaimVoucherServiceReceive show={showServiceReceived} voucherDetail={voucherDetail} onClose={onContinueClaim} />
            {/* ------- popup Service Onboarding theme doctorhub/central20 ------- */}
            <ServiceOnboarding show={showServiceOnboarding} service={serviceConfig.name} onClose={onContinueToPopupConsent} />
            {/* ------- popup consent and terms ------- */}
            <PopupConsentWithTheme show={showConsentPopup} onClose={onClosePopupAct} onSubmitConsent={onSubmitAcceptTc} />
            {/* ------- popup cancel claim ------- */}
            <ClaimCancelTask
                open={showPopupCancelTask}
                taskDetail={taskAlreadyDetail}
                serviceOrigin={serviceConfig.serviceOrigin}
                // onClose={() => setState({ showPopupCancelTask: false })}
                onSubmited={() => window.location.reload()}
            />
        </>
    );
}

export default ClaimStartV2;
