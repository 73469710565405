import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/common/button';
import ServiceDetail from 'modules/page/landing/ServiceDetail';
import { cryptojs } from 'helpers';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import useAuth from 'hooks/useAuth';
import type { DataEncodeURLProps } from 'types';
import ServiceSymstom from 'modules/page/landing/ServiceSymstom';
import ServiceMethod from 'modules/page/landing/ServiceMethod';
import ServicePharmacyNearby from 'modules/page/landing/ServicePharmacyNearby';
import ServiceRating from 'modules/page/landing/ServiceRating';
import ServiceQeustionAndAnswer, { QeustionBox } from 'modules/page/landing/ServiceQnA';
import ServiceRelate from 'modules/page/landing/ServiceRelate';
import LandingLogin from 'modules/page/landing/LandingLogin';
import MetaTags from 'react-meta-tags';
import ServiceSlideBox from 'modules/page/landing/ServiceSlideBox';
import useQueryParams from 'hooks/useQueryParams';
import useThemeWithPartner from 'hooks/useThemeWithPartner';

const serviceMediaList = [
    {
        img: '/images/service-landing/media/thai-pbs.webp',
        alt: 'thai-pbs',
        title: 'Thai PBS',
    },
    {
        img: '/images/service-landing/media/bangkok-post.webp',
        alt: 'bangkok-post',
        title: 'Bangkok Post',
    },
    {
        img: '/images/service-landing/media/7-hd.webp',
        alt: '7-hd',
        title: '7 HD',
    },
];

function TLPServiceLanding() {
    const refs = useRef(null);
    const navigate = useNavigate();
    const { auth, userLogout } = useAuth();
    const timer = useRef<NodeJS.Timeout>();
    const [showMethod, setShowMethod] = useState(1);
    const { query: _params } = useQueryParams();
    const theme = useThemeWithPartner();

    const onNextPage = () => {
        // let path = process.env.REACT_APP_LIFF_REDIRECT_DEFAULT;
        let path: any = process.env.REACT_APP_WEB_URL + '/fb/line/redirect';
        let dataForEncode: DataEncodeURLProps = {
            path: '/telepharmacy/claim',
            params:
                window.location.search ||
                (['bilaxten'].some((f) => f === theme.name) && !!!_params?.hspCode ? '' : '?voucher=TLP&fromService=true'),
            data: {
                authorization: null,
                userId: null,
                autoLogin: 0,
            },
        };
        if (window.location.origin.includes('fastcare.co')) {
            path = process.env.REACT_APP_LIFF_REDIRECT_FASTCARE;
            dataForEncode = {
                ...dataForEncode,
                params: window.location.search || '?voucher=fastcare01&fromService=true',
                data: {
                    authorization: null,
                    userId: null,
                    autoLogin: 0,
                    partner: '18',
                },
            };
        }
        if (_params.theme === 'abbott') {
            // path = process.env.REACT_APP_LIFF_REDIRECT_ABBOTT;
            dataForEncode = {
                path: '/telepharmacy/claim',
                params: '',
                data: { partner: '87', autoLogin: 0 },
            };
        }
        if (_params.theme === 'bilaxten') {
            let setParams = '';
            // path = process.env.REACT_APP_LIFF_REDIRECT_BILAXTEN;
            if (_params.hspCode === 'HSPCP00181') {
                setParams = '?hspCode=HSPCP00181';
            }
            if (_params.hspCode === 'HSPCP00218') {
                setParams = '?hspCode=HSPCP00218';
            }
            dataForEncode = {
                path: '/telepharmacy/claim',
                params: setParams,
                data: { partner: '88', autoLogin: 0 },
            };
        }
        if (auth.isAuth) {
            return navigate(dataForEncode.path + dataForEncode.params);
        }
        return window.location.assign(`${path}?to=${cryptojs.encode(dataForEncode)}`);
    };

    const onChangeMethod = (method: number) => {
        let _method = method;

        clearInterval(timer.current);
        setShowMethod(_method);

        timer.current = setInterval(() => {
            _method++;

            if (_method < 4) {
                setShowMethod(_method);
            } else {
                setShowMethod(1);
                _method = 1;
            }
        }, 5000);
    };

    const onContinueService = () => {
        let path = process.env.REACT_APP_LIFF_REDIRECT_DEFAULT;
        let dataForEncode: DataEncodeURLProps = {
            path: '/telepharmacy/claim',
            params: window.location.search || '?voucher=TLP&fromService=true',
            data: {
                authorization: null,
                userId: null,
                autoLogin: 0,
            },
        };
        if (window.location.origin.includes('fastcare.co')) {
            path = process.env.REACT_APP_LIFF_REDIRECT_FASTCARE;
            dataForEncode = {
                ...dataForEncode,
                params: window.location.search || '?voucher=fastcare01&fromService=true',
                data: {
                    authorization: null,
                    userId: null,
                    autoLogin: 0,
                    partner: '18',
                },
            };
        }
        const encode = cryptojs.encode(dataForEncode);
        if (auth.isAuth) {
            return dataForEncode.path + dataForEncode.params;
        }
        return path + '?to=' + encode;
    };

    const params = useMemo(() => {
        const origin = window.location.origin;
        return origin.includes('fastcare.co') ? '?voucher=fastcare01' : '?voucher=TLP';
    }, [window.location.origin]);

    useEffect(() => {
        onChangeMethod(1);

        return () => clearInterval(timer.current);
    }, []);

    useEffect(() => {
        if (_params.fbads === '1' || (['default', 'default_pharmcareads'].every((e) => theme.name !== e) && theme.name)) {
            userLogout();
        }
    }, [theme]);

    return (
        <>
            <MetaTags>
                <meta name='keywords' content='ซื้อยา ออนไลน์' />
                <title>ซื้อยาทันที - ปรึกษาเภสัชกร จัดส่งยาถึงบ้าน</title>
                <meta
                    name='description'
                    content='ปรึกษาเภสัชกรเพื่อซื้อยาทันที และ จัดส่งถึงบ้าน ทั่วประเทศฟรี. กทม และ ปริมณฑลรับยาภายใน 2-3 ชม.'
                />
                <meta name='robots' content='index,follow' />
                <link rel='canonical' href='https://app.pharmcare.co/service/telepharmacy' />
                <link rel='alternate' href='https://app.pharmcare.co/service/telepharmacy' hrefLang='th-TH' />
            </MetaTags>
            <ServiceDetail>
                <div className='landing-service'>
                    {['bilaxten'].some((f) => f === theme.name) || ['bilaxten'].some((f) => f === _params.theme) ? (
                        <div className='service-panel service-content'>
                            {/* <div className='title'>บริการ</div> */}
                            <h1 className='sub-title'>น้ำมูกไหล คัน จาม จากภูมิแพ้</h1>
                            <h4 className='detail'>
                                <div>วิดีโอคอลรับคำแนะนำจากเภสัชกรก่อนเพื่อรับยา </div>
                                {/* <div>พร้อมบริการรับยาที่ร้านหรือจัดส่งถึงบ้าน</div> */}
                            </h4>
                            {_params.hspCode === 'HSPCP00181' ? (
                                <div className='service-image'>
                                    <img
                                        className='desktop'
                                        src='/images/service-landing/service-tlp-bilaxten-boots-desktop.png'
                                        alt='ปรึกษาเภสัชกร และ ซื้อยา ออนไลน์ ร้านขายยาออนไลน์'
                                        title='ปรึกษาเภสัชกร และ ซื้อยา ออนไลน์ ร้านขายยาออนไลน์'
                                    />
                                    <img
                                        className='mobile'
                                        src='/images/service-landing/service-tlp-bilaxten-boots-mobile.png'
                                        alt='ปรึกษาเภสัชกร และ ซื้อยา ออนไลน์ ร้านขายยาออนไลน์'
                                        title='ปรึกษาเภสัชกร และ ซื้อยา ออนไลน์ ร้านขายยาออนไลน์'
                                    />
                                </div>
                            ) : _params.hspCode === 'HSPCP00218' ? (
                                <div className='service-image'>
                                    <img
                                        className='desktop'
                                        src='/images/service-landing/service-tlp-bilaxten-topscare-desktop.png'
                                        alt='ปรึกษาเภสัชกร และ ซื้อยา ออนไลน์ ร้านขายยาออนไลน์'
                                        title='ปรึกษาเภสัชกร และ ซื้อยา ออนไลน์ ร้านขายยาออนไลน์'
                                    />
                                    <img
                                        className='mobile'
                                        src='/images/service-landing/service-tlp-bilaxten-topscare-mobile.png'
                                        alt='ปรึกษาเภสัชกร และ ซื้อยา ออนไลน์ ร้านขายยาออนไลน์'
                                        title='ปรึกษาเภสัชกร และ ซื้อยา ออนไลน์ ร้านขายยาออนไลน์'
                                    />
                                </div>
                            ) : (
                                <div className='service-image'>
                                    <img
                                        className='desktop'
                                        src='/images/service-landing/service-tlp-bilaxten-desktop.png'
                                        alt='ปรึกษาเภสัชกร และ ซื้อยา ออนไลน์ ร้านขายยาออนไลน์'
                                        title='ปรึกษาเภสัชกร และ ซื้อยา ออนไลน์ ร้านขายยาออนไลน์'
                                    />
                                    <img
                                        className='mobile'
                                        src='/images/service-landing/service-tlp-bilaxten-mobile.png'
                                        alt='ปรึกษาเภสัชกร และ ซื้อยา ออนไลน์ ร้านขายยาออนไลน์'
                                        title='ปรึกษาเภสัชกร และ ซื้อยา ออนไลน์ ร้านขายยาออนไลน์'
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='service-panel service-content'>
                            <div className='title'>บริการ</div>
                            <h1 className='sub-title'>ปรึกษาเภสัชกรเพื่อรับยา</h1>
                            <h4 className='detail'>
                                <div>วิดีโอคอล รับคำแนะนำจากเภสัชกรก่อนเพื่อรับยา </div>
                                {/* <div>พร้อมบริการรับยาที่ร้านหรือจัดส่งถึงบ้าน</div> */}
                            </h4>

                            <div className='service-image'>
                                <img
                                    className='desktop'
                                    src='/images/service-landing/service-tlp-desktop.webp'
                                    alt='ปรึกษาเภสัชกร และ ซื้อยา ออนไลน์ ร้านขายยาออนไลน์'
                                    title='ปรึกษาเภสัชกร และ ซื้อยา ออนไลน์ ร้านขายยาออนไลน์'
                                />
                                <img
                                    className='mobile'
                                    src='/images/service-landing/service-tlp-mobile.webp'
                                    alt='ปรึกษาเภสัชกร และ ซื้อยา ออนไลน์ ร้านขายยาออนไลน์'
                                    title='ปรึกษาเภสัชกร และ ซื้อยา ออนไลน์ ร้านขายยาออนไลน์'
                                />
                            </div>
                        </div>
                    )}
                    <div className='service-panel service-detail'>
                        {/* <div className='affiliated-pharmacy'>
                        <LandingAffiliatedPharmacy />
                    </div> */}

                        <h3 className='title'>รายละเอียดบริการ</h3>
                        <div className='detail'>
                            <div className='icon'>
                                <IconConfigWithTheme pageName='landing1_tlp' iconName='sevice_detail_1' />
                            </div>
                            <h4>จ่ายยาตามอาการ / ยาทานประจำ</h4>
                        </div>
                        <div className='detail'>
                            <div className='icon'>
                                <IconConfigWithTheme pageName='landing1_tlp' iconName='sevice_detail_2' />
                            </div>
                            <h4>ปรึกษาอาการกับเภสัชกรคุณภาพก่อนรับยา</h4>
                        </div>
                        {/* <div className='detail'>
                        <div className='icon'>
                            <IconConfigWithTheme pageName='landing1_tlp' iconName='dalivery_time' />
                        </div>
                        <h4>เลือกรับยาที่ร้าน หรือ ระยะเวลาจัดส่งประมาณ 2-3 ชม.</h4>
                    </div> */}
                        <h3 className='title'>อัตราค่าบริการ</h3>
                        <div className='sevice-rate-panel'>
                            <div className='sevice-rate'>
                                <div className='list special-row'>
                                    <div className='list-icon'>
                                        <IconConfigWithTheme pageName='landing1_tlp' iconName='medicine_rate' />
                                    </div>
                                    <div className='list-content'>
                                        <h3 className='list-title'>ค่ายา</h3>
                                        <h4 className='list-detail'>
                                            <div>ราคาตามหน้าร้านยา</div>
                                            <div>(ถ้าไม่มีการซื้อยา อาจมีค่าปรึกษาเภสัชกรรม 50 บาท)</div>
                                        </h4>
                                    </div>
                                </div>
                                <div className='list'>
                                    <div className='list-icon'>
                                        <IconConfigWithTheme pageName='landing1_tlp' iconName='dalivery_rate' />
                                    </div>
                                    <div className='list-content'>
                                        <h3 className='list-title'>ค่าจัดส่ง</h3>
                                        <h4 className='list-detail'>
                                            {/* <div>ไม่เกิน 45 บาท หากมีการจัดส่ง</div> */}
                                            <div>
                                                {['abbott', 'bilaxten'].some((f) => f === theme.name)
                                                    ? 'ฟรีค่าจัดส่ง ถึง 31 ธันวาคม 2567'
                                                    : '65 บาท (หากมีการจัดส่ง)'}
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                                {/* <div className='list'>
                                <div className='list-icon'>
                                    <IconConfigWithTheme pageName='landing1_tlp' iconName='management_rate' />
                                </div>
                                <div className='list-content'>
                                    <h3 className='list-title'>ค่าธรรมเนียมการจัดการ</h3>
                                    <h4 className='list-detail'>
                                        <div className='line-through'>55 บาท </div>
                                        <div>(*ฟรีค่าธรรมเนียมถึง ธันวาคม 2566)</div>
                                    </h4>
                                </div>
                            </div> */}
                            </div>
                            <div className='panel-footer' ref={refs}>
                                <Button variant='info' onClick={() => onNextPage()} data-test-id='consultNow'>
                                    ปรึกษาเภสัชกรตอนนี้
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='consult-now'>
                    <div className='custom-icon'>
                        <IconConfigWithTheme pageName='landing1_tlp' iconName='consult_now' />
                    </div>
                    <div className='custom-btn'>
                        <Button variant='info' id='myBtn' onClick={() => onNextPage()}>
                            ปรึกษาเภสัชกรตอนนี้
                        </Button>
                    </div>
                </div>
            </ServiceDetail>
            {['bilaxten'].some((f) => f === theme.name) || ['bilaxten'].some((f) => f === _params.theme) ? (
                <ServiceSymstom imageBgc='orange'>
                    <div className='landing-panel'>
                        <div className='landing-icon'>
                            <img src='/images/service-landing/pharmcare-short-logo.svg' alt='pharmcare-icon' />
                        </div>
                        <h2>
                            <div className='title'>ภูมิแพ้กำเริบ</div>
                            <div className='sub-title'>ปรึกษาเภสัชกร</div>
                        </h2>
                        <div className='landing-content'>
                            <div className='landing-image'>
                                <img
                                    src='/images/service-landing/symptoms-tlp.webp'
                                    title='คนไม่สบาย คนไอ เป็นไข้'
                                    alt='คนไม่สบาย คนไอ เป็นไข้'
                                />
                            </div>
                            <div className='symptoms'>
                                <div className='symptoms-about'>อาการเกี่ยวกับ...</div>
                                <ul className='symptoms-list'>
                                    <li>
                                        <h4>จาม</h4>
                                    </li>
                                    <li>
                                        <h4>ผื่นแดง</h4>
                                    </li>
                                    <li>
                                        <h4>คัดจมูก</h4>
                                    </li>
                                    <li>
                                        <h4>ผื่นบวมนูน</h4>
                                    </li>
                                    <li>
                                        <h4>คันจมูก</h4>
                                    </li>
                                    <li>
                                        <h4>ผิวคันยุบยิบ</h4>
                                    </li>
                                    <li>
                                        <h4>น้ำมูกไหล</h4>
                                    </li>
                                    <li>
                                        <h4>คันตา ตาแดง</h4>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ServiceSymstom>
            ) : (
                <ServiceSymstom imageBgc='orange'>
                    <div className='landing-panel'>
                        <div className='landing-icon'>
                            <img src='/images/service-landing/pharmcare-short-logo.svg' alt='pharmcare-icon' />
                        </div>
                        <h2>
                            <div className='title'>อาการเบื้องต้น</div>
                            <div className='sub-title'>ที่เภสัชกรของเราให้คำปรึกษา</div>
                        </h2>
                        <div className='landing-content'>
                            <div className='landing-image'>
                                <img
                                    src='/images/service-landing/symptoms-tlp.webp'
                                    title='คนไม่สบาย คนไอ เป็นไข้'
                                    alt='คนไม่สบาย คนไอ เป็นไข้'
                                />
                            </div>
                            <div className='symptoms'>
                                <div className='symptoms-about'>อาการเกี่ยวกับ...</div>
                                <ul className='symptoms-list'>
                                    <li>
                                        <h4>ปวดหัว</h4>
                                    </li>
                                    <li>
                                        <h4>เป็นไข้/เป็นหวัด</h4>
                                    </li>
                                    <li>
                                        <h4>ปวดท้อง</h4>
                                    </li>
                                    <li>
                                        <h4>ท้องเสีย</h4>
                                    </li>
                                    <li>
                                        <h4>ผื่นขึ้น</h4>
                                    </li>
                                    <li>
                                        <h4>กรดไหลย้อน</h4>
                                    </li>
                                    <li>
                                        <h4>ภูมิแพ้</h4>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ServiceSymstom>
            )}
            <ServiceMethod>
                <div className='landing-panel'>
                    <h3 className='title'>ขั้นตอนในการรับบริการ</h3>
                    <div className='method'>
                        <div className='method-row mark-1'>
                            <div className='method-row mark-2'>
                                <div className='method-col'>
                                    <div
                                        className={showMethod === 1 ? 'method-title active' : 'method-title'}
                                        onClick={() => onChangeMethod(1)}
                                    >
                                        <div className='circle'>1</div>
                                        <h3>ปรึกษาเภสัชกร</h3>
                                    </div>
                                </div>
                                <div className='method-col'>
                                    <div
                                        className={showMethod === 2 ? 'method-title active' : 'method-title'}
                                        onClick={() => onChangeMethod(2)}
                                    >
                                        <div className='circle'>2</div>
                                        <h3>จ่ายยาตามอาการ</h3>
                                    </div>
                                </div>
                                <div className='method-col'>
                                    <div
                                        className={showMethod === 3 ? 'method-title active' : 'method-title'}
                                        onClick={() => onChangeMethod(3)}
                                    >
                                        <div className='circle'>3</div>
                                        <h3>รอรับยา</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='method-row mark-3 line-center'>
                                <div className={showMethod === 1 ? 'method-col active' : 'method-col'}>
                                    <div className='method-image'>
                                        <img src='/images/service-landing/method-1.webp' title='ปรึกษาเภสัชกร' alt='ปรึกษาเภสัชกร' />
                                    </div>
                                </div>
                                <div className={showMethod === 2 ? 'method-col active' : 'method-col'}>
                                    <div className='method-image'>
                                        <img src='/images/service-landing/method-2.webp' title='ร้านขายยา จัดยา' alt='ร้านขายยา จัดยา' />
                                    </div>
                                </div>
                                <div className={showMethod === 3 ? 'method-col active' : 'method-col'}>
                                    <div className='method-image'>
                                        <img src='/images/service-landing/method-3.webp' title='ส่งยาถึงบ้าน' alt='ส่งยาถึงบ้าน' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='method-row'>
                            <div className={showMethod === 1 ? 'method-col active' : 'method-col'}>
                                <div className='method-detail'>
                                    <div className='method-title'>
                                        <div>1. ปรึกษาเภสัชกร</div>
                                    </div>
                                    <p>กรอกข้อมูลสุขภาพ อาการของคุณ และเริ่มต้นสนทนากับเภสัชกร ภายใน 5 นาที</p>
                                </div>
                            </div>
                            <div className={showMethod === 2 ? 'method-col active' : 'method-col'}>
                                <div className='method-detail'>
                                    <div className='method-title'>
                                        <div>2. จ่ายยาตามอาการ</div>
                                    </div>
                                    <p>หากต้องการรับยา เภสัชกรจะจัดยาตามอาการเบื้องต้นให้คุณ</p>
                                </div>
                            </div>
                            <div className={showMethod === 3 ? 'method-col active' : 'method-col'}>
                                <div className='method-detail'>
                                    <div className='method-title'>
                                        <div>3. รอรับยา</div>
                                    </div>
                                    <p>รับยาที่ร้านหรือ ขอรับยาผ่านการจัดส่ง (หากเป็นความต้องการของคนไข้)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ServiceMethod>
            {!['abbott', 'bilaxten'].some((f) => f === theme.name) && _params.fbads !== '1' && (
                <ServicePharmacyNearby serviceLink={onContinueService()} />
            )}
            <ServiceRating>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>เภสัชกรอธิบายวิธีใช้ยาละเอียดเหมือนไปรับบริการที่ร้านยาจริงๆเลยครับ</div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlp' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>เจ</div>
                            <div className='rating-date'>27 May 2020</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>ได้รับยารวดเร็วมากเลยค่ะ สะดวกสบาย ไม่ต้องเสียเวลาเดินทางไปที่ร้านยา</div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlp' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>เกณิกา</div>
                            <div className='rating-date'>15 June 2020</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            วันนั้นไม่สบาย อยู่คอนโดคนเดียว เลยเลือกใช้บริการ เภสัชกรอธิบายโรคดีมาก ทำให้ทราบว่าต้องทำอย่างไร ขอบคุณมาก ๆ
                            เลยครับ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlp' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>ไมค์ </div>
                            <div className='rating-date'>30 Aug 2020</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>ขอบคุณพี่เภสัชที่ทำให้ทราบว่ายาที่ใช้อยู่ประจำ ทำให้ปวดท้องได้</div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlp' iconName='star' />

                            <span>4</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>ธัญมล</div>
                            <div className='rating-date'>24 Dec 2020</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            ตอนแรกตั้งใจจะซื้อยาเดิมที่เคยใช้ โชคดีที่วันนั้นกดใช้บริการนี้
                            เภสัชกรอธิบายทำให้ทราบว่าหากใช้ยาเดิมอาการจะแย่ลง ตอนนี้หายแล้ว ขอบคุณเภสัชกรมากค่ะ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlp' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>ธีรยา</div>
                            <div className='rating-date'>12 Jan 2021</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>ประทับใจ ตรงที่มีการแนบคำแนะนำการปฏิบัติตัวมาให้อ่านด้วย</div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlp' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>วิน</div>
                            <div className='rating-date'>16 Apr 2021</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            คุณแม่ท้องเสีย ไม่มีรถไปร้านยา เลยกดใช้บริการ หลังจากนั้นรอไม่นานคุณแม่ก็ได้รับยา ไม่งั้นวันนั้นคงกังวลมากค่ะ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlp' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>นนท์</div>
                            <div className='rating-date'>20 May 2021</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            ชอบมากเลยครับ ไม่ต้องออกไปเสี่ยงโควิดที่ร้านยา รอประมาณชั่วโมงกว่า ๆ ก็ได้รับยาแล้ว
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlp' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>พลอย</div>
                            <div className='rating-date'>7 Jan 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            อยากซื้อยาออนไลน์ แต่ไม่แน่ใจว่าจะได้ยาจริงไหม กลัวปลอม แต่พอมาเจอบริการของฟาร์มแคร์ เห็นว่ามีเภสัชกร
                            มีร้านยาที่มีตัวตนจริง ๆ เลยทดลองใช้ แล้วก็ประทับใจค่ะ คิดว่าคงกลับมาใช้บริการซ้ำค่ะ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlp' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>แป้ง</div>
                            <div className='rating-date'>13 Feb 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            หลายที่ต้องโหลดแอพก่อนใช้บริการ แต่ที่นี่ไม่ต้อง ทำให้รู้สึกสะดวกใจที่จะใช้ ขั้นตอนก็ไม่ยุ่งยาก
                            รอไม่นานก็ได้รับบาที่บ้านเลบครับ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlp' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>กีรติ</div>
                            <div className='rating-date'>20 May 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            ตั้งแต่โควิดระบาด ก็ไม่อยากไปรอรับยาที่รพ. จึงลองใช้บริการซื้อยาออนไลน์กับฟาร์มแคร์
                            เภสัชกรสามารถแนะนำและจัดยาให้เราได้เลยค่ะ ดีงามมาก
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlp' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>เมย์</div>
                            <div className='rating-date'>27 May 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            ปกติมีร้านยา ใกล้บ้านที่ชอบไป วันนึงทางร้านแนะนำว่าสามารถซื้อยา delivery ผ่านระบบฟาร์มแคร์ได้
                            โดยต้องเลือกชื่อร้าน ก็จะได้คุยกับเภสัชกรเหมือนมาที่ร้าน และรอรับยาอยู่ที่บ้านได้เลย เพิ่งพบทางออกดี ๆ
                            สะดวกมากค่ะ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlp' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>ชาคริยา</div>
                            <div className='rating-date'>3 June 2022</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            ผมชอบบริการขายยาออนไลน์ ของฟาร์มแคร์ครับ เพราะมั่นใจว่ายาที่ได้มาจากร้านขายยา ที่มีเภสัชกรดูแลจริง ๆ
                            ราคายาก็เหมือนไปซื้อที่ร้าน ก่อนใช้ก็จะงงก่อนว่าจะได้รับยาจากร้านยาชื่ออะไร
                            จึ่งมั่นใจว่ายาที่ได้รับจะถูกต้องและมีคุณภาพครับ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_tlp' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>ญาตา</div>
                            <div className='rating-date'>10 June 2022</div>
                        </div>
                    </div>
                </div>
            </ServiceRating>
            <ServiceSlideBox serviceList={serviceMediaList} title='สื่่อที่พูดถึงเรา' />
            <ServiceQeustionAndAnswer>
                <div className='landing-panel'>
                    <h2 className='title'>คำถามที่พบบ่อย</h2>
                    <div className='landing-content'>
                        <div className='landing-image'>
                            <img src='/images/service-landing/question.webp' alt='คำถามที่พบบ่อย' title='คำถามที่พบบ่อย' />
                        </div>
                        <div className='question-n-answer'>
                            <QeustionBox question={'1. มีบริการในจังหวัดใดบ้าง'}>
                                บริการปรึกษาเภสัชกรเพื่อซื้อยาสามารถใช้ได้ทุกจังหวัดทั่วประเทศไทย
                            </QeustionBox>
                            <QeustionBox question={'2. ค่าจัดส่งคิดอย่างไร (การจัดส่งซึ่งเป็นความต้องการของคนไข้)'}>
                                {/* ไม่เกิน 45 บาท  */}
                                65 บาท (หากมีการจัดส่ง)
                                {/*  <p>ฟรีค่าจัดส่ง ตั้งแต่วันนี้ถึง {moment('2565-09-30').format('D MMM YYYY')}</p>
                             <>
                                    <p>
                                        เขตกทม.และปริมณฑล คิดค่าบริการ 57 บาท สำหรับ 1 กิโลเมตรแรก และเพิ่ม 7 บาท สำหรับทุกๆ 1 กิโลเมตรถัดไป
                                        ซึ่งจะได้รับยาภายใน 3 ชั่วโมง
                                    </p>
                                    <div>สำหรับต่างจังหวัด คิดค่าจัดส่ง 80 บาท และจะได้รับยาภายใน 1-3 วัน</div>
                                </> */}
                            </QeustionBox>
                            {/* <QeustionBox question={'3. ใช้เวลาจัดส่ง นานเท่าไหร่'}>
                            <p>สำหรับบริการจัดส่งยาในเขตกทมและปริมณฑล คนไข้จะได้รับยาภายใน 3 ชม</p>
                            <div>
                                ยกเว้นรหัสไปรษณีย์ (10280, 10550, 10560, 10570, 11150, 12110, 12170, 74120, 74130) ใช้เวลาจัดส่ง 1-3 วัน
                            </div>
                            <div>สำหรับบริการจัดส่งยาในต่างจังหวัด คนไข้จะได้รับยาภายใน 1-3 วัน</div>
                        </QeustionBox> */}
                            <QeustionBox question={'3. ช่วงเวลาเปิดให้บริการ คือช่วงใด'}>10:00-20:00 ทุกวัน</QeustionBox>
                            <QeustionBox question={'4. ราคายาคิดอย่างไร'}>
                                ราคายา เป็นราคาเดียวกับที่ลูกค้ามาซื้อหน้าร้าน ของแต่ละร้าน (ไม่บวกเพิ่ม)
                            </QeustionBox>
                            <QeustionBox question={'5. มียาอะไรบ้าง'}>
                                <p>รายการยาที่มีจะเหมือนที่จำหน่ายหน้าร้านขายยา แต่ละร้าน</p>
                            </QeustionBox>
                            <QeustionBox question={'6. โทรเข้ามาปรึกษาเภสัชกรหรือถามอาการ โดยไม่ซื้อยาได้ไหม'}>
                                สำหรับบริการที่มีการปรึกษาเภสัชกรโดยไม่มีการซื้อยา อาจมีค่าบริการทางเภสัชกรรม 50 บาท
                                (ขึ้นอยู่กับเภสัชกรผู้ให้บริการ)
                            </QeustionBox>
                            <QeustionBox question={'7. เราปรึกษาเภสัชกรผ่านช่องทางใด'}>
                                สนทนากับเภสัชกรผ่าน video call ผ่านระบบ Pharmcare
                            </QeustionBox>
                            <QeustionBox question={'8. ถ้าต้องการซื้อยาเฉยๆ จำเป็นต้องปรึกษากับเภสัชกรไหม'}>
                                เภสัชกรจะพิจารณาจ่ายยาตามอาการ และความเหมาะสมสำหรับผู้ป่วยแต่ละราย
                                จึงจำเป็นต้องปรึกษาเภสัชกรก่อนซื้อยาทุกครั้ง
                            </QeustionBox>
                            <QeustionBox question={'9. ชำระเงินผ่านช่องทางใดได้บ้าง'}>
                                สามารถชำระด้วยบัตรเครดิต/เดบิท หรือ โอนเงินผ่านธนาคารโดยใช้ Promptpay
                            </QeustionBox>
                            {/* <QeustionBox question={'11. มีบริการเก็บเงินปลายทางหรือไม่'}>ไม่มีค่ะ</QeustionBox> */}
                            <QeustionBox question={'10. ถ้าต้องการซื้อยา ต้องดาวน์โหลด app ไหม'}>ไม่ต้องค่ะ</QeustionBox>
                            <QeustionBox question={'11. ยาจะมาจากร้านยาไหน'}>
                                ระบบจะค้นหาร้านขายยาใกล้ตัวคนไข้เพื่อให้บริการ และ จัดส่งยาให้
                            </QeustionBox>
                        </div>
                    </div>
                </div>
            </ServiceQeustionAndAnswer>
            {!['abbott', 'bilaxten'].some((f) => f === theme.name) && !['abbott', 'bilaxten'].some((f) => f === _params.theme) && (
                <ServiceRelate serviceList={['telemed', 'storeNearby']} />
            )}
            <LandingLogin onNextPath={'/telepharmacy/claim'} params={params} />
        </>
    );
}

export default TLPServiceLanding;
