import styled from '@emotion/styled';
import { Section } from 'components/layouts/page-layout';
import { breakpoint } from 'helpers';

export const StyleExtendsSection = styled(Section)`
    .claim-input-voucher .exp-collapse-complete-title {
        color: var(--body-color);
        font-size: 80%;
        font-weight: 400;
        margin-bottom: 0.5rem;
    }
    .insurance-btn {
        position: absolute;
        top: -1px;
        right: -60px;

        > img {
            width: 25px;
            margin-right: 3px;
        }

        ${breakpoint('XS')} {
            top: -4px;
            right: -80px;
            > img {
                width: 30px;
                margin-right: 3px;
            }
        }
    }
`;

export const ClaimPidStyled = styled(Section)`
    label: claim-pid;

    --thai-id-card-header-height: 125px;
    --default-minus: calc(
        var(--body-height) - var(--navbar-height) - var(--breadcrumbs-height) - var(--thai-id-card-header-height) - 100px
    );
    --profile-picture-header-height: 100px;
    --default-minus: calc(
        var(--body-height) - var(--navbar-height) - var(--breadcrumbs-height) - var(--profile-picture-header-height) - 100px
    );

    ${breakpoint('XS')} {
        --thai-id-card-header-height: 100px;
        --profile-picture-header-height: 95px;
    }

    .custom-panel {
        ${breakpoint('XS')} {
            padding: 0 24px;
        }
    }
    .content-insurance {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        ${breakpoint('XS')} {
            justify-content: left;
        }
    }
    .-action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 500px;
        margin: auto auto 0;
        .-transparent {
            box-shadow: none;
            background-color: transparent !important;
            padding: 0.275rem;
            text-align: left;
            &:disabled,
            &:active {
                color: var(--text-normal-2);
                cursor: not-allowed;
            }
        }
        .btn-confirm {
            width: 100%;
            max-width: 200px;

            ${breakpoint('XS')} {
                width: 48%;
                /* max-width: 150px; */
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        &.-action-rabbit {
            justify-content: center;
        }
    }

    .ads-coverage {
        position: relative;
        margin-bottom: 1rem;
        .ads-coverage-content {
            display: flex;
            align-items: flex-end;
        }
        .ads-coverage-content-left {
            width: 100%;
            padding: 12px;
            border-radius: 12px;
            margin-right: -25%;
            padding-right: 25%;
            background-color: #e5f7f6;
            .ads-coverage-content-title {
                font-weight: 500;
                color: var(--primary-color);
            }
            .ads-coverage-content-descript {
                font-size: 80%;
            }
        }
        .ads-coverage-content-right {
            width: 22%;
        }
    }

    .form-check-input.is-invalid ~ .form-check-label,
    .was-validated .form-check-input:invalid ~ .form-check-label {
        border: solid 1px #dc3545;
    }

    .select-insurance {
        label {
            width: 120px;
            height: 120px;

            ${breakpoint('XS')} {
                width: 90px;
                height: 90px;
            }
        }
    }

    .custom-form-panel {
        padding: 0;
        overflow: hidden;

        ${breakpoint('XS')} {
            overflow: visible;
        }
    }
    .thai-id-card-header {
        height: var(--thai-id-card-header-height);
        text-align: center;
        padding: 24px;

        ${breakpoint('XS')} {
            padding: 0;
        }
    }
    .thai-id-card-panel {
        min-height: calc(var(--default-minus) - 60px);
        margin-top: auto;
        background-color: #000000ae;
    }

    .profile-picture-header {
        height: var(--profile-picture-header-height);
        text-align: center;
        padding: 24px;
    }
    .profile-picture-panel {
        min-height: calc(var(--default-minus) - 30px);
        margin-top: auto;
        background-color: #000000ae;
    }
`;

export const ClaimNotFoundStyled = styled(Section)`
    label: claim-coverage-not-found;

    display: flex;
    padding-top: 40px;

    ${breakpoint('XS')} {
        padding-top: 0;
    }

    .custom-panel {
        position: relative;
        width: 96%;
        max-width: 750px;
        text-align: center;
        padding: 60px 120px;
        margin: auto;
        height: fit-content;
        background-size: cover;
        background-image: url('/images/claim/claim-not-found-bg.png');

        ${breakpoint('XS')} {
            width: 100%;
            box-shadow: none;
            padding: 30px 20px;
            margin: 0;
            margin-bottom: 5rem;
            border-radius: 0;
        }

        .image-frame {
            height: 250px;
            margin-bottom: 1rem;

            ${breakpoint('XS')} {
                margin-top: 5rem;
            }
        }
    }
`;
