import React from 'react';
import SidebarProfile from './sidebar-profile';
import Sidebarlanguage from './sidebar-language';
import SidebarAddress from './sidebar-address';
import SidebarInfo from './sidebar-info';
import { SideBarContentWrapper } from './Sidebar.styled';

function SideBarContent({ hasInProgress }) {
    return (
        <SideBarContentWrapper className='content'>
            <SidebarProfile hasInProgress={hasInProgress} />
            <Sidebarlanguage />
            <SidebarInfo />
            <SidebarAddress />
        </SideBarContentWrapper>
    );
}

export default SideBarContent;
