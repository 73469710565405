import { createContext, useRef, useState } from 'react';
import LiftUpV2Component from './LiftUpV2Component';
import { useLocation } from 'react-router-dom';

export type LiftUpV2ContextProps = {
    open: (props: any) => void;
    close: (id: any) => void;
    destroy: () => void;
    update: (props: any) => void;
};
export const LiftUpV2Context = createContext<LiftUpV2ContextProps>(null!);

function LiftUpV2Provider({ children }: { children: React.ReactNode }) {
    const [liftUpList, setLiftUpList] = useState<any[]>([]);
    const cn = useRef(0);

    const destroyLiftUp = () => {
        setLiftUpList([]);
    };
    const updateLiftUp = (props: any) => {
        let temp = [...liftUpList];

        const liftIndex = temp.findIndex((f) => f.id === props.id);

        if (liftIndex < 0) return;

        temp.splice(liftIndex, 1, { ...props });
    };
    const closeLiftUp = (id: any) => {
        const temp = [...liftUpList];
        const liftIndex = liftUpList.findIndex((f) => f.id === id);

        temp.splice(liftIndex, 1);

        setLiftUpList((prev) => {
            if (prev.length <= 0) return [];

            return [...temp];
        });
    };

    const openLiftUp = (props: any) => {
        cn.current += 1;

        setLiftUpList((prev) => [...prev, { ...props, id: cn.current }]);

        return { ...props, id: cn.current };
    };

    const values: LiftUpV2ContextProps = {
        open: openLiftUp,
        close: closeLiftUp,
        destroy: destroyLiftUp,
        update: updateLiftUp,
    };

    return (
        <LiftUpV2Context.Provider value={values}>
            {children}
            {liftUpList.map((props, index) => (
                <LiftUpV2Component key={index} {...props} close={() => closeLiftUp(props.id)} />
            ))}
        </LiftUpV2Context.Provider>
    );
}

export default LiftUpV2Provider;
