import DetectSwitchDom from 'components/common/detect-switch-dom';
import { PageLoading } from 'components/common/PageLoading';
import EXPPopupJs from 'components/common/exp-popupjs';
import { cryptojs, paramsjs } from 'helpers';
import useAuth from 'hooks/useAuth';
import usePageContext from 'hooks/usePageContext';
import useWindowSize from 'hooks/useWindowSize';
import React, { useEffect } from 'react';
import { Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';
import Layout from 'components/layouts';
import PopupCookieConsent from 'components/common/popup-cookie-consent';
import { useTranslation } from 'react-i18next';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import moment from 'moment';
import useLiftUpV2 from 'components/common/LiftUpV2/useLiftUpV2';
import { POST } from 'api';

export default function ProtectRoute({
    path,
    service = {},
    isLoadPage = false,
    component: Component,
    googleAnalytics,
    requireInProgress = false,
    requireNavebar = true,
    requireParamsTask = false,
    requireRemoteConfig = false,
    requireMobileLanguage = false,
    pageLoadingType = 'spinner',
    ...props
}) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { i18n } = useTranslation();
    const { width, height } = useWindowSize();
    const { isPageLoad, setPageLoaded, setProps } = usePageContext();
    const { auth, profile, getProfile, updateProfile } = useAuth();
    const theme = useThemeWithPartner();
    const liftup = useLiftUpV2();
    const { task } = paramsjs.query(location.search);

    const callAction = ({ startAction, close }) => {
        startAction();
        window.location.replace(`${service.servicePath}/claim`);
        close();
    };

    useEffect(() => {
        const shouldChangeLanguage = () => {
            if (profile.language && profile.language.toLowerCase() !== i18n.language) {
                i18n.changeLanguage(profile.language.toLowerCase());
                moment.locale(profile.language.toLowerCase());
                console.log('%c>> changeLanguage:', 'background: #00f; color: #fff', profile.language.toLowerCase());
            }
        };
        shouldChangeLanguage();
    }, [profile.language]);

    useEffect(() => {
        setProps({ serviceConfig: service });

        return () => {
            liftup.destroy();
        };
    }, []);

    //call save action
    useEffect(() => {
        const getUserAction = () => {
            console.log('%c>> call user action path : ', 'background: #000; color: #fff', window.location.pathname);

            try {
                POST('/user/action', { path: window.location.pathname, referrer: document.referrer || null, userAction: 'render' });
            } catch (error) {
                console.log('/user/action error', error);
            }
        };
        getUserAction();
    }, [window.location.pathname]);

    console.log('%c>> render route:', 'background: #f00; color: #fff', window.location.pathname);
    // return
    if (!auth.isAuth && window.location.origin.includes('fastcare.co')) {
        const from = {
            path: location.pathname,
            params: location.search || '',
            data: { partner: '18', autoLogin: 0 },
        };

        return <Navigate to={'/user/line?to=' + cryptojs.encode(from)} />;
    }
    if (!auth.isAuth && window.location.origin.includes('yaphrom')) {
        const from = {
            path: location.pathname,
            params: location.search || '',
            data: { partner: '15', autoLogin: 0 },
        };

        return <Navigate to={'/user/line?to=' + cryptojs.encode(from)} />;
    }

    if (!auth.isAuth) {
        const from = {
            path: location.pathname,
            params: location.search || '',
            data: {},
        };

        return <Navigate to={'/user/line?to=' + cryptojs.encode(from)} />;
    }

    if (requireParamsTask && !task) {
        return EXPPopupJs.alert({
            icon: 'warning',
            title: 'เกิดข้อผิดพลาด',
            message: 'กรุณาทำการตรวจสอบสิทธิ์ใหม่อีกครั้ง',
            classNames: { message: 'font-detail2' },
            buttons: [
                {
                    label: 'ตกลง',
                    variant: 'primary',
                    action: callAction,
                },
            ],
        });
    }
    return (
        <>
            <DetectSwitchDom />
            <PageLoading type={pageLoadingType} active={isLoadPage && !isPageLoad} data-test-id='pageLoading' />
            <Layout showHeader={requireNavebar} requireInProgress={requireInProgress} mobileLanguage={requireMobileLanguage}>
                <Component
                    match={{ path: location.pathname, params: params }}
                    location={location}
                    navigate={navigate}
                    params={paramsjs.query(location.search)}
                    next={(pathname, state) => navigate(pathname + location.search, { state: state })}
                    theme={theme.name}
                    auth={auth}
                    profile={profile}
                    getProfile={getProfile}
                    updateProfile={updateProfile}
                    bodyWidth={width}
                    bodyHeight={height}
                    serviceConfig={service}
                    setLoadPage={setPageLoaded}
                    {...props}
                />
                <PopupCookieConsent />
            </Layout>
        </>
    );
}
