import React, { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes, BrowserRouter } from 'react-router-dom';
import RoutesManagement from './routes';
import { AuthProvider } from 'context/AuthProvider';
import { PageProvider } from 'context/PageProvider';
import globalStyle from 'styles/global';
import { getCookie } from 'cookies-next';
import { hotjar } from 'react-hotjar';
import ThemeWithPartnerProvider, { GlobalStyle } from 'context/ThemeWithPartnerProvider';
import DeveloperTools from 'components/common/deverloper-tools';
import EXPPopupJs from 'components/common/exp-popupjs';
import EXPLiftUp from 'components/common/exp-lift-up';
import { LiftUpV2Provider } from 'components/common/LiftUpV2';
import { ConfigThemeProvider } from 'context/ConfigThemeProvider';

// Initialize Sentry
Sentry.init({
    enabled: process.env.REACT_APP_ENABLED_SENTRY === 'true',
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // dsn: 'https://cbd867348a6a3b4160d177cb069bfce2@o4506019189227520.ingest.us.sentry.io/4508673488322560',

    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),

        new CaptureConsole({
            levels: ['error', 'debug'],
            // levels: ['debug'],
        }),
        new Sentry.Replay({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
        }),
    ],
    beforeSend(event: any, hint: any) {
        // If the event is from the console, check for an object and stringify it
        //  if (event.message && typeof event.message === 'object') {
        //      event.message = JSON.stringify(event.message); // Serialize the object
        //  }
        if (hint?.captureContext && typeof hint?.originalException === 'string' && hint?.originalException?.includes('[object Object]')) {
            const _message: any = hint?.captureContext?.extra?.arguments?.find((f) => typeof f === 'object') || '';
            event.message = hint?.originalException?.replace('[object Object]', ' ') + JSON.stringify(_message);
        }
        return event; // Send the modified event to Sentry
    },
    tracesSampleRate: 1.0,
    tracePropagationTargets: [process.env.REACT_APP_WEB_URL ?? '', process.env.REACT_APP_API_URL ?? ''],
    denyUrls: [/http:\/\/localhost/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

const scripts = [
    {
        src: `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_GTAG_KEY}`,
    },
    {
        content: `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_GTAG_KEY}');
      `,
    },
    {
        content: `
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l !== 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY}');
      `,
    },
    {
        content: `
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = true;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = true;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${process.env.REACT_APP_FB_EVENTS_KEY}');
        fbq('track', 'PageView');
      `,
    },
];

const InitPopup: React.FC = () => {
    const popupRef = useRef<any>(null);

    useEffect(() => {
        const forceCode = () => EXPPopupJs.close();

        EXPPopupJs.register(popupRef.current);
        window.addEventListener('onchangepage', forceCode);

        return () => window.removeEventListener('onchangepage', forceCode);
    }, []);

    return <EXPPopupJs.Init ref={popupRef} />;
};

const InitLiftUp: React.FC = () => {
    const liftUpRef = useRef<any>(null);

    useEffect(() => {
        const forceCode = () => EXPLiftUp.close();

        EXPLiftUp.register(liftUpRef.current);
        window.addEventListener('onchangepage', forceCode);

        return () => window.removeEventListener('onchangepage', forceCode);
    }, []);

    return <EXPLiftUp.Init ref={liftUpRef} />;
};

function App() {
    useEffect(() => {
        const consent = getCookie('localConsent', { domain: 'pharmcare.co' });

        const initScript = () => {
            if (consent) {
                if (process.env.NODE_ENV !== 'test') {
                    hotjar.initialize(Number(process.env.REACT_APP_HJID), Number(process.env.REACT_APP_HJ_NUMBER));
                }

                scripts.forEach((script) => {
                    const el = document.createElement('script');
                    if (script.src) {
                        el.src = script.src;
                        el.async = true;
                    } else if (script.content) {
                        el.type = 'text/javascript';
                        el.async = true;
                        el.innerHTML = script.content;
                    }
                    document.body.appendChild(el);
                });
            }
        };

        initScript();
    }, [window.fbq]);

    return (
        <BrowserRouter>
            <AuthProvider>
                {/* config theme */}
                <ConfigThemeProvider>
                    <PageProvider>
                        <ThemeWithPartnerProvider>
                            <LiftUpV2Provider>
                                <GlobalStyle styles={globalStyle} />
                                <DeveloperTools />
                                <RoutesManagement />
                                <InitPopup />
                                <InitLiftUp />
                            </LiftUpV2Provider>
                        </ThemeWithPartnerProvider>
                    </PageProvider>
                </ConfigThemeProvider>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
