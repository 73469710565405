import React, { useState } from 'react';
import styled from '@emotion/styled';
import { breakpoint } from 'helpers';
import { TabPane, Tabs } from 'components/common/tabs-pane';
import Button from 'components/common/button';
import { Col, Row } from 'components/common/grid-layout';
import { useTranslation } from 'react-i18next';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import EXPLiftUp from 'components/common/exp-lift-up';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';

const Style = styled.div`
    label: popup-service-onboarding;

    padding: 2rem 24px;
    /* background-color: var(--lightseagreen-3); */
    .title {
        font-weight: 500;
        font-size: 1.333rem;
        color: var(--primary-color);
    }
    .sub-title {
        font-family: 'Sarabun-Regular', sans-serif;
        color: var(--text-normal-1);
    }
    .tabs-info {
        position: relative;
        margin: 1.5rem 0;
        .tabs-tab {
            font-weight: 500;
            border-radius: 10px;
            border: solid 1px #b1e6e6;
            background-color: #d1efef;
            color: var(--lightseagreen-1);
            padding: 12px;
            min-width: 145px;
            min-height: 65px;
            justify-content: center;
            z-index: 0;
            ${breakpoint('XS')} {
                width: 100%;
            }
        }
        .tabs-content {
            position: relative;
            min-height: 450px;
            .info {
                padding: 24px;
                min-height: 445px;
                border-radius: 10px;
                background-color: var(--white);
                margin-top: -0.5rem;

                position: relative;
                z-index: 1;
            }
            .img-bottom {
                position: absolute;

                bottom: -12px;
                right: 0;
                z-index: 2;

                img {
                    max-width: 240px;
                }
            }
        }
        .tabs-tab-active {
            color: #f9f9fa;
            border: solid 1px var(--second-color);
            background-color: var(--second-color);
        }
    }

    .btn-continue {
        display: block;
        width: 100%;
        max-width: 350px;
        margin-top: 3rem;
        margin-left: auto;
        margin-right: auto;

        ${breakpoint('XS')} {
            max-width: 400px;
        }
    }
`;
const StyleTabOne = styled.div`
    label: popup-render-tab1;
    .sevice-info {
        .list {
            align-items: center;
            .img {
                position: relative;
                max-width: 160px;
                margin: auto;
                padding: 1rem;
                ${breakpoint('XS')} {
                    max-width: 120px;
                    padding: 0.5rem;
                }
            }

            .detail {
                position: relative;
                margin-top: 1rem;
                padding: 0 0.5rem;
            }
            .title {
                display: flex;
                color: #005150;
                font-size: 1.223rem;
                font-weight: 500;
                align-items: center;

                .circle {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 1.334rem;
                    height: 1.334rem;
                    color: #fff;
                    font-size: 1rem;
                    font-weight: initial;
                    margin-right: 0.5rem;
                    border-radius: 50%;
                    background-color: #1aacaa;
                }
            }
        }
    }
`;
const StyleTabTwo = styled.div`
    label: popup-render-tab2;
    .sevice-rate {
        margin-top: 1.5rem;

        .list {
            line-height: 1.5;
            margin: 1rem 0;
            .header {
                display: flex;
                .icon {
                    height: 1.4rem;
                    color: #00acaa;
                    font-size: 1.4rem;

                    margin-right: 1rem;
                }

                .title {
                    color: #005150;
                    font-size: 1.2rem;
                    font-weight: 500;
                }
            }
            .detail {
                margin-left: 2.4rem;
                color: var(--text-normal-1);
                font-family: var(--font-detail2);
            }
        }
    }
`;

function RenderTabOne({ service = '' }) {
    const { t } = useTranslation();

    const serviceList = {
        telepharmacy: [
            {
                number: '1',
                imgUrl: '/images/service-landing/method-1.webp',
                title: 'ปรึกษาเภสัชกร',
                description: 'กรอกข้อมูลและสนทนากับเภสัชกร',
            },
            {
                number: '2',
                imgUrl: '/images/service-landing/method-2.webp',
                title: 'จ่ายยาตามอาการ',
                description: 'เภสัชกรจ่ายยาให้ตามอาการเบื้องต้น',
            },
            {
                number: '3',
                imgUrl: '/images/service-landing/method-3.webp',
                title: 'รอรับยา',
                description: 'ชำระเงินและรอรับรายการสินค้าที่บ้าน',
            },
        ],
        tlmdoctorhub: [
            {
                number: '1',
                imgUrl: '/images/service-landing/method-tlm-1.webp',
                title: 'ปรึกษาแพทย์',
                description: 'กรอกข้อมูลและสนทนากับแพทย์',
            },
            {
                number: '2',
                imgUrl: '/images/service-landing/method-tlm-2.webp',
                title: 'รอใบสั่งยา',
                description: 'แพทย์จ่ายใบสั่งยาและยาให้ตามอาการ',
            },
            {
                number: '3',
                imgUrl: '/images/service-landing/method-3.webp',
                title: 'รอรับยา',
                description: 'ชำระเงินและรอรับรายการสินค้าที่บ้าน',
            },
        ],
        tlmcentral20: [
            {
                number: '1',
                imgUrl: '/images/service-landing/method-tlm-1.webp',
                title: t('claim:service_on_boarding.doctor_consultation'),
                description: t('claim:service_on_boarding.input_info'),
            },
            {
                number: '2',
                imgUrl: '/images/service-landing/method-tlm-2.webp',
                title: t('claim:service_on_boarding.awaiting_prescription'),
                description: t('claim:service_on_boarding.doctor_prescripts'),
            },
            {
                number: '3',
                imgUrl: '/images/service-landing/method-3.webp',
                title: t('claim:service_on_boarding.pick_up'),
                description: t('claim:service_on_boarding.confirm_medicine'),
            },
        ],
    };
    return (
        <StyleTabOne>
            <div className='sevice-info'>
                {serviceList[service]?.map((value, index) => (
                    <Row className='list' key={index}>
                        <Col md={6} xs={4} className='img'>
                            <img src={value.imgUrl} alt={value.tile} />
                        </Col>
                        <Col md={6} xs={8}>
                            <div className='title'>
                                <div className='circle'>{value.number}</div>
                                <div>{value.title}</div>
                            </div>
                            <div className='detail'>{value.description}</div>
                        </Col>
                    </Row>
                ))}
            </div>
        </StyleTabOne>
    );
}
function RenderTabTwo({ service = '' }) {
    const { t } = useTranslation();

    const serviceList = {
        telepharmacy: [
            {
                imgUrl: null,
                title: 'ค่ายา (ถ้ามี)',
                description: 'ราคาตามหน้าร้าน',
            },
            {
                imgUrl: null,
                iconName: 'telepharmacy2',
                title: 'ค่าจัดส่ง',
                description: 'เริ่มต้นที่ 57 บาท',
            },
        ],
        tlmdoctorhub: [
            {
                imgUrl: null,
                iconName: 'tlmdoctorhub1',
                title: 'ค่าแพทย์',
                description: 'ค่าปรึกษาแพทย์ ตามคลินิกกำหนด',
            },
            {
                imgUrl: null,
                iconName: 'tlmdoctorhub2',
                title: 'ค่ายา (ถ้ามี)',
                description: 'ราคาตามหน้าร้าน',
            },
            {
                imgUrl: null,
                iconName: 'tlmdoctorhub3',
                title: 'ค่าจัดส่ง',
                description: 'เริ่มต้นที่ 57 บาท',
            },
        ],
        tlmcentral20: [
            {
                imgUrl: null,
                iconName: 'tlmcentral20_1',
                title: t('claim:service_on_boarding.doctor_fee'),
                description: '250 ' + t('common:thb'),
            },
            {
                imgUrl: null,
                iconName: 'tlmcentral20_2',
                title: t('claim:service_on_boarding.medicine_cost'),
                description: t('claim:service_on_boarding.store_price'),
            },
            {
                imgUrl: '/images/service-landing/pharmcare-short-logo.svg',
                iconName: '',
                title: t('claim:service_on_boarding.system_fee'),
                description: '160.5 ' + t('common:thb'),
            },
        ],
    };
    return (
        <StyleTabTwo>
            <div className='sevice-rate'>
                {serviceList[service]?.map((value, index) => (
                    <div className='list' key={index}>
                        <div className='header'>
                            <div className='icon'>
                                {value.icon && (
                                    <IconConfigWithTheme pageName='service_onboarding' iconName={value.iconName}></IconConfigWithTheme>
                                )}
                                {value.img && <img src={value.imgUrl} alt='' />}
                            </div>
                            <div className='title'>{value.title}</div>
                        </div>
                        <div className='detail'>{value.description}</div>
                    </div>
                ))}
            </div>
        </StyleTabTwo>
    );
}

/**
 *
 * @param {{
 * show: boolean
 * service: string
 * onClose: () => void
 * }} props
 * @returns
 */
function ServiceOnboarding({ show = false, service = '', onClose = () => null }) {
    const { t } = useTranslation();
    const theme = useThemeWithPartner();

    //
    const [tabsKey, setTabsKey] = useState('01');
    //
    const showPopup = React.useMemo(() => show, [show]);

    const onChangeTabs = (e) => {
        setTabsKey(e);
    };
    const getTitle = () => {
        if (theme.name !== 'central20' && service === 'tlm') {
            return t('claim:service_on_boarding.telemeds_consultation');
        }
        if (theme.name === 'central20' && service === 'tlm') {
            return t('claim:service_on_boarding.topsCare_service_process');
        }
        if (theme.name === 'central30' && service === 'tlm') {
            return t('claim:service_on_boarding.topsCare_service_process');
        }
        return 'ปรึกษาเภสัชกร';
    };

    const getServiceType = () => {
        if (theme.name === 'central30') return 'tlmcentral20';
        if (theme.name === 'central20') return 'tlmcentral20';
        if (theme.name === 'doctorhub' && service === 'tlm') return 'tlmdoctorhub';
        if (theme.name === 'doctorhub' && service !== 'tlm') return 'telepharmacy';
        return '';
    };

    return (
        <EXPLiftUp.slideUp
            show={showPopup}
            zIndex={1001}
            isClickOutSideClose={false}
            backgroundColor='#e4f7f7'
            data-test-id='popupServiceOnboarding'
        >
            <Style>
                <div className='header'>
                    <div className='title'>{getTitle()}</div>
                    <div className='sub-title'>{t('claim:service_on_boarding.service_hour')}</div>
                </div>
                <Tabs className='tabs-info' activeKey={tabsKey} onChange={onChangeTabs}>
                    <TabPane
                        key='01'
                        tab={
                            theme.name === 'central20' && service === 'tlm'
                                ? t('claim:service_on_boarding.doctor_consulation')
                                : t('claim:service_on_boarding.service_process')
                        }
                    >
                        <div className='info'>
                            <RenderTabOne service={getServiceType()} />
                        </div>
                    </TabPane>
                    <TabPane key='02' tab={t('claim:service_on_boarding.service_cost')}>
                        <>
                            <div className='info'>
                                <RenderTabTwo service={getServiceType()} />
                            </div>
                            <div className='img-bottom'>
                                <img src='/images/service-onboarding/service-rate.png' alt='service-rate' />
                            </div>
                        </>
                    </TabPane>
                </Tabs>
                {tabsKey === '01' && (
                    <Button data-test-id='continue' variant='primary' className='btn-continue' onClick={() => setTabsKey('02')}>
                        {t('claim:service_on_boarding.proceed')}
                    </Button>
                )}
                {tabsKey === '02' && (
                    <Button data-test-id='startService' variant='primary' className='btn-continue' onClick={onClose}>
                        {t('claim:service_on_boarding.initiate_service')}
                    </Button>
                )}
            </Style>
        </EXPLiftUp.slideUp>
    );
}

export default ServiceOnboarding;
