import React from 'react';
import { SERVICE_CONFIG } from 'constants/service-config';
import ClaimSelectV2 from 'pages/task/claim/ClaimSelectV2';
import ClaimPidV2 from 'pages/task/claim/ClaimPidV2';
import ClaimStartV2 from 'pages/task/claim/ClaimStartV2';

// claim

// screening

const ScreeningFinal = React.lazy(() => import('pages/task/screening').then((module) => ({ default: module.ScreeningFinal })));
// phr existing
const Existing = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.Existing })));
const ExistingPersonalInfo = React.lazy(() =>
    import('pages/task/phr-existing').then((module) => ({ default: module.ExistingPersonalInfo }))
);
const ExistingHealthInfo = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.ExistingHealthInfo })));
const ExistingAdditionalInfo = React.lazy(() =>
    import('pages/task/phr-existing').then((module) => ({ default: module.ExistingAdditionalInfo }))
);
const ExistingLab = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.ExistingLab })));
const ExistingLabDetail = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.ExistingLabDetail })));

// phr new
const PhrNewWhatDisease = React.lazy(() => import('pages/task/phr-new/phr-new-disease'));
const PhrNewDrugAllergy = React.lazy(() => import('pages/task/phr-new/phr-new-drug-allergy'));
const PhrNewDate = React.lazy(() => import('pages/task/phr-new/phr-new-date'));
const PhrNewGender = React.lazy(() => import('pages/task/phr-new/phr-new-gender'));
const PhrNewBody = React.lazy(() => import('pages/task/phr-new/phr-new-body'));
const PhrNewAdditional = React.lazy(() => import('pages/task/phr-new/phr-new-additional'));
// service srv
const SrvMedOrder = React.lazy(() => import('pages/task/srvmed-order/srvmed-order'));
const SrvMedFinish = React.lazy(() => import('pages/task/srvmed-finish/srvmed-finish'));
// call
const JoinCallOndemand = React.lazy(() => import('pages/task/join-call/join-call-ondemand'));
const InCallTLPOndemand = React.lazy(() => import('pages/task/in-call/in-call-tlp-ondemand'));
const AppointmentReject = React.lazy(() => import('pages/task/appointment').then((module) => ({ default: module.AppointmentReject })));
const NoPayment = React.lazy(() => import('pages/task/no-payment/no-payment'));
const Payment = React.lazy(() => import('pages/task/payment/payment'));
const MobileBanking = React.lazy(() => import('pages/task/payment/mobile-banking'));
const ThaiIdCard = React.lazy(() => import('pages/task/profile-id-card/thai-id-card'));
const ProfileFace = React.lazy(() => import('pages/task/profile-face/profile-face'));
const ExistingPharmacistNoteList = React.lazy(() => import('pages/task/phr-existing/existing-pharmacist-note-list'));
const ExistingPharmacistNoteDetail = React.lazy(() => import('pages/task/phr-existing/existing-pharmacist-note-detail'));
export const NutritionistService = [
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/nt/claim/select',
        component: ClaimSelectV2,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 1, level: 60 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        requireRemoteConfig: true,
        path: '/nt/claim/pid',
        component: ClaimPidV2,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 1, level: 30 },
    },

    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        requireRemoteConfig: true,
        path: '/nt/claim/profile/id-card',
        component: ThaiIdCard,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 1, level: 30 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        requireRemoteConfig: true,
        path: '/nt/claim/profile/face',
        component: ProfileFace,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 1, level: 30 },
    },

    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        requireRemoteConfig: true,
        requireMobileLanguage: true,
        exact: true,
        isLoadPage: true,
        path: '/nt/claim',
        component: ClaimStartV2,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 1, level: 10 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/nt/phr/existing',
        component: Existing,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/nt/phr/existing/personal-info',
        component: ExistingPersonalInfo,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/nt/phr/existing/health-info',
        component: ExistingHealthInfo,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/nt/phr/existing/additional-info',
        component: ExistingAdditionalInfo,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/nt/phr/existing/lab/:detail',
        component: ExistingLabDetail,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 1, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/nt/phr/existing/lab',
        component: ExistingLab,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 1, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/nt/phr/existing/pharmacist-note/list/:cpaType',
        component: ExistingPharmacistNoteList,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/nt/phr/existing/pharmacist-note/detail/:detailType/:cpaType/:cpaTID',
        component: ExistingPharmacistNoteDetail,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/nt/phr/new/disease',
        component: PhrNewWhatDisease,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 2, level: 0 },
    },

    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/nt/phr/new/drug-allergy',
        component: PhrNewDrugAllergy,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 2, level: 10 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/nt/phr/new/dob',
        component: PhrNewDate,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 2, level: 15 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/nt/phr/new/gender',
        component: PhrNewGender,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 2, level: 20 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/nt/phr/new/body',
        component: PhrNewBody,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 2, level: 25 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/nt/phr/new/additional',
        component: PhrNewAdditional,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 2, level: 30 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        path: '/nt/screening/final',
        component: ScreeningFinal,
        service: SERVICE_CONFIG.nutritionist,
        progress: { name: 'nt', step: 2, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        requireRemoteConfig: true,
        exact: true,
        isLoadPage: true,
        path: '/nt/consult/service/payment',
        component: Payment,
        service: SERVICE_CONFIG.nutritionistConsult,
        progress: { name: 'nt', step: 3, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        requireRemoteConfig: true,
        isLoadPage: true,
        path: '/nt/consult/service/payment/mobile',
        component: MobileBanking,
        service: SERVICE_CONFIG.nutritionistConsult,
        progress: { name: 'nt', step: 3, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/nt/consult/service/no-payment',
        component: NoPayment,
        service: SERVICE_CONFIG.nutritionistConsult,
        progress: { name: 'nt', step: 3, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        path: '/nt/consult/service/join-call',
        component: JoinCallOndemand,
        service: SERVICE_CONFIG.nutritionistConsult,
        progress: { name: 'nt', step: 4, level: 20 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        path: '/nt/consult/service/appointment-reject',
        component: AppointmentReject,
        service: SERVICE_CONFIG.nutritionistConsult,
    },
    {
        requireNavebar: false,
        requireParamsTask: true,
        requireInProgress: true,
        path: '/nt/consult/service/in-call',
        component: InCallTLPOndemand,
        service: SERVICE_CONFIG.nutritionistConsult,
    },

    {
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/nt/fulfillment/service/srvmed-order',
        component: SrvMedOrder,
        service: SERVICE_CONFIG.nutritionistFulfillment,
        progress: { name: 'nt', step: 4, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/nt/fulfillment/service/srvmed-finish',
        component: SrvMedFinish,
        servicePath: '/nt/fulfillment',
        service: SERVICE_CONFIG.nutritionistFulfillment,
        progress: { name: 'nt', step: 4, level: 100 },
    },
];
