import React from 'react';
import useAuth from 'hooks/useAuth';
import { css, Global } from '@emotion/react';
import { generateCssColor, generateCssComponents, generateCssLang } from 'theme/generate-theme/generate-theme2';
import useConfigTheme from 'hooks/useConfigTheme';

export type ThemeWithPartnerProps = { name: string; css: any; setTheme: () => void };

const ThemeWithPartnerContext = React.createContext<ThemeWithPartnerProps>(null!);

const ThemeWithPartnerConsumer = ThemeWithPartnerContext.Consumer;

// < { styles: ThemedCssFunction } >
// export const GlobalStyle = createGlobalStyle<{ styles: any }>`${(props) => props.styles}`;
export const GlobalStyle = ({ styles }: { styles: any }) => <Global styles={styles} />;

function ThemeWithPartnerProvider({ children }) {
    const { auth, profile } = useAuth();
    const { configTheme, configThemeName } = useConfigTheme();
    const [theme, setTheme] = React.useState('default');

    const onChangeTheme = React.useCallback(() => {
        return setTheme(theme);
    }, []);

    React.useEffect(() => {
        // init;
        const getThemeWithPartner = () => {
            setTheme(configThemeName);
        };

        getThemeWithPartner();
    }, [configThemeName, profile.theme, JSON.stringify(auth)]);

    const values: ThemeWithPartnerProps = React.useMemo(() => {
        const _configThemeColor = generateCssColor(configTheme.color);
        const _configThemeFont = generateCssLang(configTheme.font);
        const _configThemeComponents = generateCssComponents(configTheme.components);
        const _theme = css`
            html[theme=${`"${theme}"`}] {
                ${_configThemeColor}
                ${_configThemeFont}
                ${_configThemeComponents}
            }
        `;

        const HTMLElement = document.querySelector('html');

        HTMLElement?.setAttribute('theme', theme);

        return {
            name: theme,
            css: _theme,
            setTheme: onChangeTheme,
        };
    }, [theme, configTheme, setTheme]);
    return (
        <ThemeWithPartnerContext.Provider value={values}>
            <GlobalStyle styles={values.css} />
            {children}
        </ThemeWithPartnerContext.Provider>
    );
}

export { ThemeWithPartnerProvider, ThemeWithPartnerContext, ThemeWithPartnerConsumer };
export default ThemeWithPartnerProvider;
