import React from 'react';
import { SERVICE_CONFIG } from 'constants/service-config';
import ClaimStartV2 from 'pages/task/claim/ClaimStartV2';
import ClaimNotFoundV2 from 'pages/task/claim/ClaimNotFoundV2';
import ClaimSelectV2 from 'pages/task/claim/ClaimSelectV2';
import ClaimPidV2 from 'pages/task/claim/ClaimPidV2';

// claim
const ClaimAuto = React.lazy(() => import('pages/task/claim').then((module) => ({ default: module.ClaimAuto })));
const InCallTLPOndemand = React.lazy(() => import('pages/task/in-call/in-call-tlp-ondemand'));
const RejectTaskTLP = React.lazy(() => import('pages/task/reject-task/reject-task-tlp'));
const RecreateTask = React.lazy(() => import('pages/task/recreate-task'));
const MedConfirm = React.lazy(() => import('pages/task/confirm-med/med-confirm'));
const MedReject = React.lazy(() => import('pages/task/reject-med/med-reject'));

// screening
const ScreeningFinal = React.lazy(() => import('pages/task/screening').then((module) => ({ default: module.ScreeningFinal })));
const ScreeningMedicine = React.lazy(() => import('pages/task/screening').then((module) => ({ default: module.ScreeningMedicine })));
// phr existing
const Existing = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.Existing })));
const ExistingPersonalInfo = React.lazy(() =>
    import('pages/task/phr-existing').then((module) => ({ default: module.ExistingPersonalInfo }))
);
const ExistingHealthInfo = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.ExistingHealthInfo })));
const ExistingAdditionalInfo = React.lazy(() =>
    import('pages/task/phr-existing').then((module) => ({ default: module.ExistingAdditionalInfo }))
);
const ExistingLab = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.ExistingLab })));
const ExistingLabDetail = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.ExistingLabDetail })));

// phr new
const PhrNewWhatDisease = React.lazy(() => import('pages/task/phr-new/phr-new-disease'));
const PhrNewDrugAllergy = React.lazy(() => import('pages/task/phr-new/phr-new-drug-allergy'));
const PhrNewDate = React.lazy(() => import('pages/task/phr-new/phr-new-date'));
const PhrNewGender = React.lazy(() => import('pages/task/phr-new/phr-new-gender'));
const PhrNewBody = React.lazy(() => import('pages/task/phr-new/phr-new-body'));
const PhrNewAdditional = React.lazy(() => import('pages/task/phr-new/phr-new-additional'));
// service srv
const SrvMedOrder = React.lazy(() => import('pages/task/srvmed-order/srvmed-order'));
const SrvMedFinish = React.lazy(() => import('pages/task/srvmed-finish/srvmed-finish'));
const SrvFinish = React.lazy(() => import('pages/task/srv-finish/srv-finish'));
// choose pharmacy
const ChoosePharmacyFavourite = React.lazy(() =>
    import('pages/task/choose-pharmacy').then((module) => ({ default: module.ChoosePharmacyFavourite }))
);
const ChoosePharmacyStore = React.lazy(() =>
    import('pages/task/choose-pharmacy').then((module) => ({ default: module.ChoosePharmacyStore }))
);
// select address
const SelectAddressTLP = React.lazy(() => import('pages/task/select-address').then((module) => ({ default: module.SelectAddressTLP })));
const Location = React.lazy(() => import('pages/task/location/location'));

// med
const MedPendingTLP = React.lazy(() => import('pages/task/pending-med/med-pending-tlp'));
const MedPendingEndCallChatTLP = React.lazy(() => import('pages/task/pending-med/med-pending-end-call-chat-tlp'));
// pickup
const Pickup = React.lazy(() => import('pages/task/pickup').then((module) => ({ default: module.Pickup })));
// call
const JoinCallTLP = React.lazy(() => import('pages/task/join-call/join-call-tlp'));

const NoPayment = React.lazy(() => import('pages/task/no-payment/no-payment'));
const Payment = React.lazy(() => import('pages/task/payment/payment'));
const MobileBanking = React.lazy(() => import('pages/task/payment/mobile-banking'));

//courier-status
const CourierStatus = React.lazy(() => import('pages/task/courier-status'));

const ThaiIdCard = React.lazy(() => import('pages/task/profile-id-card/thai-id-card'));
const ProfileFace = React.lazy(() => import('pages/task/profile-face/profile-face'));
const ExistingPharmacistNoteList = React.lazy(() => import('pages/task/phr-existing/existing-pharmacist-note-list'));
const ExistingPharmacistNoteDetail = React.lazy(() => import('pages/task/phr-existing/existing-pharmacist-note-detail'));
//
export const TelepharmacyService = [
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/create-claim/tlp/:language?',
        component: ClaimAuto,
        service: SERVICE_CONFIG.telepharmacy,
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telepharmacy/claim/not-found',
        component: ClaimNotFoundV2,
        service: SERVICE_CONFIG.telepharmacy,
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telepharmacy/claim/select',
        component: ClaimSelectV2,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 1, level: 60 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        requireRemoteConfig: true,
        path: '/telepharmacy/claim/pid',
        component: ClaimPidV2,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 1, level: 30 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        requireRemoteConfig: true,
        path: '/telepharmacy/claim/profile/id-card',
        component: ThaiIdCard,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 1, level: 30 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        requireRemoteConfig: true,
        path: '/telepharmacy/claim/profile/face',
        component: ProfileFace,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 1, level: 30 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        requireRemoteConfig: true,
        requireMobileLanguage: true,
        exact: true,
        isLoadPage: true,
        path: '/telepharmacy/claim',
        component: ClaimStartV2,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 1, level: 10 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        requireRemoteConfig: true,
        requireMobileLanguage: true,
        exact: true,
        isLoadPage: true,
        path: '/telepharmacy-ltc/claim',
        component: ClaimStartV2,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 1, level: 10 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        requireRemoteConfig: true,
        requireMobileLanguage: true,
        exact: true,
        isLoadPage: true,
        path: '/telepharmacy/claimv2',
        component: ClaimStartV2,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 1, level: 10 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        requireRemoteConfig: true,
        requireMobileLanguage: true,
        exact: true,
        isLoadPage: true,
        path: '/telepharmacy-ltc/claimv2',
        component: ClaimStartV2,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 1, level: 10 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telepharmacy/address',
        component: SelectAddressTLP,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telepharmacy/location',
        component: Location,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/telepharmacy/phr/existing',
        component: Existing,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telepharmacy/phr/existing/personal-info',
        component: ExistingPersonalInfo,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telepharmacy/phr/existing/health-info',
        component: ExistingHealthInfo,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telepharmacy/phr/existing/additional-info',
        component: ExistingAdditionalInfo,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telepharmacy/phr/existing/lab/:detail',
        component: ExistingLabDetail,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telepharmacy/phr/existing/lab',
        component: ExistingLab,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telepharmacy/phr/existing/pharmacist-note/list/:cpaType',
        component: ExistingPharmacistNoteList,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telepharmacy/phr/existing/pharmacist-note/detail/:detailType/:cpaType/:cpaTID',
        component: ExistingPharmacistNoteDetail,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        // isLoadPage: true,
        path: '/telepharmacy/phr/new/disease',
        component: PhrNewWhatDisease,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 0 },
    },

    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/telepharmacy/phr/new/drug-allergy',
        component: PhrNewDrugAllergy,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 10 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/telepharmacy/phr/new/dob',
        component: PhrNewDate,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 15 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/telepharmacy/phr/new/gender',
        component: PhrNewGender,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 20 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/telepharmacy/phr/new/body',
        component: PhrNewBody,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 25 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/telepharmacy/phr/new/additional',
        component: PhrNewAdditional,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 30 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        path: '/telepharmacy/screening/final',
        component: ScreeningFinal,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        path: '/telepharmacy/screening/what-medicine',
        component: ScreeningMedicine,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 2, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        googleAnalytics: true,
        path: '/telepharmacy/service/join-call',
        component: JoinCallTLP,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 3, level: 0 },
    },

    {
        requireNavebar: false,
        requireParamsTask: true,
        requireInProgress: true,
        googleAnalytics: true,
        path: '/telepharmacy/service/in-call',
        component: InCallTLPOndemand,
        service: SERVICE_CONFIG.telepharmacy,
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        path: '/telepharmacy/service/task-reject',
        component: RejectTaskTLP,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 3, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telepharmacy/service/recreate-task',
        component: RecreateTask,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 3, level: 0 },
    },

    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        googleAnalytics: true,
        path: '/telepharmacy/service/pending-med',
        component: MedPendingTLP,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 4, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        googleAnalytics: true,
        path: '/telepharmacy/service/end-call',
        component: MedPendingEndCallChatTLP,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 4, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telepharmacy/service/confirm-med/address',
        component: SelectAddressTLP,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 4, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telepharmacy/service/confirm-med/location',
        component: Location,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 4, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telepharmacy/service/confirm-med',
        component: MedConfirm,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 4, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telepharmacy/service/reject-med',
        component: MedReject,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 4, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        requireRemoteConfig: true,
        exact: true,
        googleAnalytics: true,
        isLoadPage: true,
        path: '/telepharmacy/service/payment',
        component: Payment,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 5, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        requireRemoteConfig: true,
        googleAnalytics: true,
        isLoadPage: true,
        path: '/telepharmacy/service/payment/mobile',
        component: MobileBanking,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 5, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        exact: true,
        googleAnalytics: true,
        path: '/telepharmacy/service/no-payment',
        component: NoPayment,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 5, level: 0 },
    },

    {
        requireNavebar: true,
        requireParamsTask: true,
        path: '/telepharmacy/service/srv-finish',
        component: SrvFinish,
        googleAnalytics: true,
        service: SERVICE_CONFIG.telepharmacy,
    },

    {
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telepharmacy/service/srvmed-finish',
        component: SrvMedFinish,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 6, level: 100 },
    },

    {
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telepharmacy/service/srvmed-order',
        component: SrvMedOrder,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 5, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        path: '/telepharmacy/service/pickup',
        component: Pickup,
        service: SERVICE_CONFIG.telepharmacy,
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireRemoteConfig: true,
        isLoadPage: true,
        requireInProgress: true,
        path: '/telepharmacy/choose-pharmacy-store',
        component: ChoosePharmacyStore,
        pharmcareProgram: ['VIDEO_CALL'],
        service: SERVICE_CONFIG.telepharmacy,
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireRemoteConfig: true,
        isLoadPage: true,
        requireInProgress: true,
        path: '/telepharmacy/choose-pharmacy-favourite',
        component: ChoosePharmacyFavourite,
        service: SERVICE_CONFIG.telepharmacy,
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        path: '/telepharmacy/service/courier-status',
        component: CourierStatus,
        service: SERVICE_CONFIG.telepharmacy,
        progress: { name: 'tlp_med', step: 5, level: 90 },
    },
];
