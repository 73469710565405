import React from 'react';
import { extendesClassname, getColClass } from '../utils';

/**
 *
 * @param {{
 * label: String | Element,
 * classNameLabel: String,
 * classNameField: String,
 * isInvalid: Boolean,
 * xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto' | undefined,
 * sm?: xs,
 * md?: xs,
 * lg?: xs,
 * xl?: xs,
 * xxl?: xs,
 * xxxl?: xs,
 * } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>} peops
 * @returns
 */
function FieldRadio({
    label = '',
    classNameLabel = '',
    classNameField = '',
    isInvalid = false,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    xxxl,
    children,
    selectValue,
    // onChange = () => {},
    ...props
}) {
    const inputRef = React.useRef(null);

    React.useEffect(() => {
        const setInvalid = () => {
            const _isInvalidClassname = inputRef.current?.classList?.contains('is-invalid');

            if (isInvalid) {
                inputRef.current.setCustomValidity('Invalid field.');

                if (!_isInvalidClassname) inputRef.current.classList.add('is-invalid');
            } else if (_isInvalidClassname) {
                inputRef.current.classList.remove('is-invalid');
            }
        };

        setInvalid();

        return () => inputRef.current.setCustomValidity('');
    }, [isInvalid]);

    // useEffect(() => {
    //     if (inputRef.current && props.value && selectValue === props.value) {
    //         inputRef.current.checked = true;
    //     }
    // }, [inputRef.current, props.value, selectValue]);

    return (
        <div {...extendesClassname(['exp-form-check', ...getColClass({ xs, sm, md, lg, xl, xxl, xxxl }), classNameField])}>
            <input
                {...props}
                {...extendesClassname(['exp-form-check-input', props.className])}
                // onChange={onChange}
                ref={inputRef}
                type='radio'
                id={props.id ? props.id : props.name}
            />
            <label htmlFor={props.id ? props.id : props.name} {...extendesClassname([classNameLabel])}>
                {label}
            </label>
            {children}
        </div>
    );
}

export default FieldRadio;
