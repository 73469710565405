import { paramsjs } from 'helpers';
import React, { useEffect } from 'react';
import { Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';
import { captureException } from '@sentry/react';
import useWindowSize from 'hooks/useWindowSize';
import useAuth from 'hooks/useAuth';
import usePageContext from 'hooks/usePageContext';
import DetectSwitchDom from 'components/common/detect-switch-dom';
import Layout from 'components/layouts';
import PopupCookieConsent from 'components/common/popup-cookie-consent';
import { useTranslation } from 'react-i18next';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import moment from 'moment';
import useLiftUpV2 from 'components/common/LiftUpV2/useLiftUpV2';
import { PageLoading } from 'components/common/PageLoading';
import { POST } from 'api';

function PublicRoute({
    service = {},
    component: Component,
    isLoadPage = false,
    requireNavebar = true,
    requireOnlyPublic = false,
    requireRemoteConfig = false,
    requireMobileLanguage = false,
    requireInProgress = false,
    googleAnalytics = false,
    pageLoadingType = 'spinner',
    ...props
}) {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const { i18n } = useTranslation();
    const { width, height } = useWindowSize();
    const { isPageLoad, setPageLoaded } = usePageContext();
    const { auth, profile, getProfile, updateProfile } = useAuth();
    const theme = useThemeWithPartner();
    const liftup = useLiftUpV2();

    useEffect(() => {
        const shouldChangeLanguage = () => {
            if (profile.language && profile.language.toLowerCase() !== i18n.language) {
                i18n.changeLanguage(profile.language.toLowerCase());
                moment.locale(profile.language.toLowerCase());
                console.log('%c>> changeLanguage:', 'background: #00f; color: #fff', profile.language.toLowerCase());
            }
        };
        shouldChangeLanguage();
    }, [profile.language]);

    useEffect(() => {
        return () => {
            liftup.destroy();
        };
    }, []);

    //call save action
    useEffect(() => {
        const getUserAction = () => {
            console.log('%c>> call user action path : ', 'background: #000; color: #fff', window.location.pathname);
            try {
                POST('/user/action', { path: window.location.pathname, referrer: document.referrer || null, userAction: 'render' });
            } catch (error) {
                console.log('/user/action error', error);
            }
        };
        getUserAction();
    }, [window.location.pathname]);

    console.log('%c>> render route:', 'background: #f00; color: #fff', window.location.pathname);

    if (requireOnlyPublic && auth.isAuth) return <Navigate to={'/home'} />;

    return (
        <>
            <DetectSwitchDom />
            <PageLoading type={pageLoadingType} active={isLoadPage && !isPageLoad} data-test-id='pageLoading' />
            <Layout showHeader={requireNavebar} requireInProgress={requireInProgress} mobileLanguage={requireMobileLanguage}>
                <Component
                    captureException={captureException}
                    match={{ path: window.location.pathname, params: params }}
                    location={location}
                    navigate={navigate}
                    params={paramsjs.query(location.search)}
                    theme={theme.name}
                    auth={auth}
                    profile={profile}
                    getProfile={getProfile}
                    updateProfile={updateProfile}
                    bodyWidth={width}
                    bodyHeight={height}
                    serviceConfig={service}
                    setLoadPage={setPageLoaded}
                    {...props}
                />
                <PopupCookieConsent />
            </Layout>
        </>
    );
}

export default PublicRoute;
