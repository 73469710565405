import { breakpoint, numberjs } from 'helpers';
import Button from 'components/common/button';
import React from 'react';
import styled from '@emotion/styled';
import EXPLiftUp from 'components/common/exp-lift-up';
import { useTranslation } from 'react-i18next';
import useThemeWithPartner from 'hooks/useThemeWithPartner';

const Style = styled.div`
    label: popup-voucher-receive;

    .popup-voucher-receive {
        max-height: calc(var(--body-height) - 210px);
        overflow: auto;
        ${breakpoint('XS')} {
            max-height: calc(var(--body-height) - 125px);
        }
    }
    .popup-voucher-receive-service-price {
        padding: 1.5rem 2rem;
        background-color: #e2eff0;
        height: 430px;
        border-top-left-radius: 50px;
        ${breakpoint('XS')} {
            height: 410px;
        }
    }
    .popup-voucher-receive-title {
        color: var(--text-normal-1);
        .order-circle {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            color: var(--white);
            text-align: center;
            border-radius: 50%;
            margin-right: 10px;
            background-color: var(--second-color);
        }
    }
    .popup-voucher-receive-footer {
        padding: 1.375rem 24px;
        box-shadow: 0 -5px 10px #00000020;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background-color: #fff;

        ${breakpoint('XS')} {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            text-align: center;
        }
    }
`;

/**
 *
 * @param {{
 * show: boolean
 * voucherDetail: any
 * onClose: () => void
 * }} props
 * @returns
 */
function ClaimVoucherServiceReceive({ show = false, voucherDetail = {}, onClose = () => null }) {
    const { t } = useTranslation();
    const theme = useThemeWithPartner();

    return (
        <EXPLiftUp.slideUp show={show} backgroundColor='#fff' isClickOutSideClose={false}>
            <Style>
                <div className='pt-4 '>
                    <div className='popup-voucher-receive scrollbar'>
                        <h4 className='text-primary fw-800 text-center'>
                            <img
                                style={{ width: '8%', marginRight: '1rem' }}
                                src='/images/voucher-service-receive/popup-preservice.png'
                                alt='popup-preservice'
                            />
                            {t('claim:service_receive.steps_use_service')}
                        </h4>
                        <div className='px-5 py-3'>
                            <div className='popup-voucher-receive-title'>
                                <div className='order-circle'>1</div>
                                <strong>{t('claim:service_receive.consult_with_pharmacist')}</strong>
                                <div className='mt-2'>{t('claim:service_receive.explain_your_symptoms')}</div>
                            </div>
                            <div className='popup-voucher-receive-title mt-2'>
                                <div className='order-circle'>2</div>
                                <strong>{t('claim:service_receive.confirm_your_medication')}</strong>
                                <div className='mt-2'>{t('claim:service_receive.pharmacist_dispense_medication')}</div>
                            </div>
                            <div className='popup-voucher-receive-title mt-2'>
                                <div className='order-circle'>3</div>
                                <strong>{t('claim:service_receive.wait_for_medication')}</strong>
                                <div className='mt-2'>{t('claim:service_receive.get_medication_delivery')}</div>
                            </div>
                            <div className='mt-2 text-gray-2' style={{ fontSize: '0.825rem' }}>
                                {t('claim:service_receive.if_issues', { lineId: theme.name === 'default' ? ' @app.pharmcare' : '' })}
                            </div>
                        </div>
                        <div className='popup-voucher-receive-service-price'>
                            <h4 className='text-primary fw-800 text-center'>{t('claim:service_receive.service_fees')}</h4>
                            <div className='popup-voucher-receive-title'>
                                <div className='order-circle'>1</div>
                                <strong>
                                    {t('claim:service_receive.pharmacist_fee')} {numberjs(voucherDetail.serviceCost).format('double', 2)}{' '}
                                    {t('common:thb')}
                                </strong>
                            </div>
                            <div className='popup-voucher-receive-title mt-2'>
                                <div className='order-circle'>2</div>
                                <strong>{t('claim:service_receive.medicine_fee')}</strong>
                                <div className='mt-2'>{t('claim:service_receive.medicine_prices')}</div>
                            </div>
                            <div className='popup-voucher-receive-title mt-2'>
                                <div className='order-circle'>3</div>
                                <strong>{t('claim:service_receive.distribution_fee')}</strong>
                                <div className='mt-2'>{t('claim:service_receive.charged_by_distance')}</div>
                            </div>
                            <div className='popup-voucher-receive-title mt-2'>
                                <div className='order-circle'>4</div>
                                <strong>
                                    {t('claim:service_receive.management_fee')} {numberjs(voucherDetail.serviceFee).format('double', 2)}{' '}
                                    {t('common:thb')}
                                </strong>
                                <div className='mt-2'>{t('claim:service_receive.system_fee')}</div>
                                <div className='mt-2 text-gray-2'>{t('claim:service_receive.vat')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mx-auto popup-voucher-receive-footer'>
                    <div className='text-center'>
                        <Button data-test-id='continue' variant='primary' className='w-100 maw-400' onClick={onClose}>
                            {t('claim:service_receive.next_continue')}
                        </Button>
                    </div>
                </div>
            </Style>
        </EXPLiftUp.slideUp>
    );
}

export default ClaimVoucherServiceReceive;
