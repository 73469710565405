import React from 'react';
import { SERVICE_CONFIG } from 'constants/service-config';
import ClaimStartV2 from 'pages/task/claim/ClaimStartV2';
import ClaimNotFoundV2 from 'pages/task/claim/ClaimNotFoundV2';
import ClaimSelectV2 from 'pages/task/claim/ClaimSelectV2';
import ClaimPidV2 from 'pages/task/claim/ClaimPidV2';

// import page
// screening

const ScreeningFinal = React.lazy(() => import('pages/task/screening').then((module) => ({ default: module.ScreeningFinal })));
// phr existing
const Existing = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.Existing })));
const ExistingPersonalInfo = React.lazy(() =>
    import('pages/task/phr-existing').then((module) => ({ default: module.ExistingPersonalInfo }))
);
const ExistingHealthInfo = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.ExistingHealthInfo })));
const ExistingAdditionalInfo = React.lazy(() =>
    import('pages/task/phr-existing').then((module) => ({ default: module.ExistingAdditionalInfo }))
);
const ExistingLab = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.ExistingLab })));
const ExistingLabDetail = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.ExistingLabDetail })));
// phr new
const PhrNewWhatDisease = React.lazy(() => import('pages/task/phr-new/phr-new-disease'));
const PhrNewDrugAllergy = React.lazy(() => import('pages/task/phr-new/phr-new-drug-allergy'));
const PhrNewDate = React.lazy(() => import('pages/task/phr-new/phr-new-date'));
const PhrNewGender = React.lazy(() => import('pages/task/phr-new/phr-new-gender'));
const PhrNewBody = React.lazy(() => import('pages/task/phr-new/phr-new-body'));
const PhrNewAdditional = React.lazy(() => import('pages/task/phr-new/phr-new-additional'));

const SrvMedOrder = React.lazy(() => import('pages/task/srvmed-order/srvmed-order'));
const SrvMedFinish = React.lazy(() => import('pages/task/srvmed-finish/srvmed-finish'));
const SrvFinish = React.lazy(() => import('pages/task/srv-finish/srv-finish'));

const ChoosePharmacyFavourite = React.lazy(() =>
    import('pages/task/choose-pharmacy').then((module) => ({ default: module.ChoosePharmacyFavourite }))
);
const ChoosePharmacyStore = React.lazy(() =>
    import('pages/task/choose-pharmacy').then((module) => ({ default: module.ChoosePharmacyStore }))
);

// select address
const Location = React.lazy(() => import('pages/task/location/location'));
const SelectAddressTLP = React.lazy(() => import('pages/task/select-address').then((module) => ({ default: module.SelectAddressTLP })));
const SelectAddressTLM = React.lazy(() => import('pages/task/select-address').then((module) => ({ default: module.SelectAddressTLM })));
const MedPendingTLM = React.lazy(() => import('pages/task/pending-med/med-pending-tlm'));
const MedPendingTMF = React.lazy(() => import('pages/task/pending-med/med-pending-tmf'));
const MedRequestTLM = React.lazy(() => import('pages/task/request-med/med-request-tlm'));
const MedConfirm = React.lazy(() => import('pages/task/confirm-med/med-confirm'));
const MedReject = React.lazy(() => import('pages/task/reject-med/med-reject'));
const MedPendingPharmacistCancel = React.lazy(() => import('pages/task/pending-med/med-pending-pharmacist-cancel'));
// pickup
const Pickup = React.lazy(() => import('pages/task/pickup').then((module) => ({ default: module.Pickup })));

const JoinCallAppointment = React.lazy(() => import('pages/task/join-call/join-call-appointment'));
const CallCancelAppointment = React.lazy(() => import('pages/task/call-cancel/call-cancel-appointment'));
const InCallAppointment = React.lazy(() => import('pages/task/in-call/in-call-appointment'));

const CallExpired = React.lazy(() => import('pages/task/call-expired/call-expired'));

//new telepharmacy
const SelectDepartment = React.lazy(() => import('pages/task/select-department').then((module) => ({ default: module.SelectDepartment })));
const DoctorDetail = React.lazy(() => import('pages/task/select-doctor').then((module) => ({ default: module.DoctorDetail })));
const SelectDoctor = React.lazy(() => import('pages/task/select-doctor').then((module) => ({ default: module.SelectDoctor })));
const Booking = React.lazy(() => import('pages/task/booking').then((module) => ({ default: module.Booking })));
const AppointmentConfirm = React.lazy(() => import('pages/task/appointment').then((module) => ({ default: module.AppointmentConfirm })));
const AppointmentInfo = React.lazy(() => import('pages/task/appointment').then((module) => ({ default: module.AppointmentInfo })));
const AppointmentReject = React.lazy(() => import('pages/task/appointment').then((module) => ({ default: module.AppointmentReject })));
const NoPayment = React.lazy(() => import('pages/task/no-payment/no-payment'));
const Payment = React.lazy(() => import('pages/task/payment/payment'));
const MobileBanking = React.lazy(() => import('pages/task/payment/mobile-banking'));
// claim

const ClaimAuto = React.lazy(() => import('pages/task/claim').then((module) => ({ default: module.ClaimAuto })));
//courier-status
const CourierStatus = React.lazy(() => import('pages/task/courier-status'));
const ThaiIdCard = React.lazy(() => import('pages/task/profile-id-card/thai-id-card'));
const ProfileFace = React.lazy(() => import('pages/task/profile-face/profile-face'));
const ExistingPharmacistNoteList = React.lazy(() => import('pages/task/phr-existing/existing-pharmacist-note-list'));
const ExistingPharmacistNoteDetail = React.lazy(() => import('pages/task/phr-existing/existing-pharmacist-note-detail'));
export const TelemedService = [
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/create-claim/tlm/:language?',
        component: ClaimAuto,
        service: SERVICE_CONFIG.telemed,
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telemed/claim/not-found',
        component: ClaimNotFoundV2,
        service: SERVICE_CONFIG.telemed,
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telemed/claim/select',
        component: ClaimSelectV2,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 1, level: 30 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        requireRemoteConfig: true,
        path: '/telemed/claim/pid',
        component: ClaimPidV2,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 1, level: 20 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        requireRemoteConfig: true,
        path: '/telemed/claim/profile/id-card',
        component: ThaiIdCard,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 1, level: 20 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        requireRemoteConfig: true,
        path: '/telemed/claim/profile/face',
        component: ProfileFace,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 1, level: 20 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        requireRemoteConfig: true,
        requireMobileLanguage: true,
        exact: true,
        isLoadPage: true,
        path: '/telemed/claim',
        component: ClaimStartV2,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 1, level: 10 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/consult/select-department',
        component: SelectDepartment,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/consult/select-doctor',
        component: SelectDoctor,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 2, level: 30 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/consult/doctor-detail',
        component: DoctorDetail,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 2, level: 60 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/consult/change-booking/latest',
        component: Booking,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 2, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/consult/booking/latest',
        component: Booking,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 2, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/consult/booking/calendar',
        component: Booking,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 2, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/consult/change-booking/calendar',
        component: Booking,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 2, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/telemed/phr/new/disease',
        component: PhrNewWhatDisease,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 3, level: 0 },
    },

    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/telemed/phr/new/drug-allergy',
        component: PhrNewDrugAllergy,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 3, level: 10 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/telemed/phr/new/dob',
        component: PhrNewDate,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 3, level: 15 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/telemed/phr/new/gender',
        component: PhrNewGender,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 3, level: 20 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/telemed/phr/new/body',
        component: PhrNewBody,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 3, level: 25 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/telemed/phr/new/additional',
        component: PhrNewAdditional,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 2, level: 30 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/telemed/phr/existing',
        component: Existing,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 3, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/phr/existing/personal-info',
        component: ExistingPersonalInfo,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 3, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/phr/existing/health-info',
        component: ExistingHealthInfo,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 3, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/phr/existing/additional-info',
        component: ExistingAdditionalInfo,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 3, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/phr/existing/lab/:detail',
        component: ExistingLabDetail,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 3, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/phr/existing/lab',
        component: ExistingLab,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 3, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/phr/existing/pharmacist-note/list/:cpaType',
        component: ExistingPharmacistNoteList,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 3, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/phr/existing/pharmacist-note/detail/:detailType/:cpaType/:cpaTID',
        component: ExistingPharmacistNoteDetail,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 3, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/screening/final',
        component: ScreeningFinal,
        service: SERVICE_CONFIG.telemed,
        progress: { name: 'tlm', step: 3, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        requireRemoteConfig: true,
        exact: true,
        isLoadPage: true,
        path: '/telemed/consult/service/payment',
        component: Payment,
        service: SERVICE_CONFIG.telemedConsult,
        progress: { name: 'tlm', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        requireRemoteConfig: true,
        isLoadPage: true,
        path: '/telemed/consult/service/payment/mobile',
        component: MobileBanking,
        service: SERVICE_CONFIG.telemedConsult,
        progress: { name: 'tlm', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/telemed/consult/service/no-payment',
        component: NoPayment,
        service: SERVICE_CONFIG.telemedConsult,
        progress: { name: 'tlm', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        requireRemoteConfig: true,
        exact: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telemed/fulfillment/service/payment',
        component: Payment,
        service: SERVICE_CONFIG.telemedFulfillment,
        progress: { name: 'tlm_consult_med', step: 5, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        requireRemoteConfig: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telemed/fulfillment/service/payment/mobile',
        component: MobileBanking,
        service: SERVICE_CONFIG.telemedFulfillment,
        progress: { name: 'tlm_consult_med', step: 5, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telemed/fulfillment/service/no-payment',
        component: NoPayment,
        service: SERVICE_CONFIG.telemedFulfillment,
        progress: { name: 'tlm', step: 4, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/consult/service/appointment-confirm',
        component: AppointmentConfirm,
        service: SERVICE_CONFIG.telemedConsult,
        progress: { name: 'tlm', step: 5, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        path: '/telemed/consult/service/appointment-reject',
        component: AppointmentReject,
        service: SERVICE_CONFIG.telemedConsult,
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        path: '/telemed/consult/service/appointment-info',
        component: AppointmentInfo,
        service: SERVICE_CONFIG.telemedConsult,
        progress: { name: 'tlm_consult_med', step: 1, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/consult/service/join-call',
        component: JoinCallAppointment,
        service: SERVICE_CONFIG.telemedConsult,
        progress: { name: 'tlm_consult_med', step: 1, level: 20 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        isLoadPage: false,
        path: '/telemed/consult/service/call-expired',
        component: CallExpired,
        service: SERVICE_CONFIG.telemedConsult,
        progress: { name: 'tlm_consult_med', step: 1, level: 30 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: false,
        path: '/telemed/consult/service/doctor-cancel',
        component: CallCancelAppointment,
        service: SERVICE_CONFIG.telemedConsult,
        progress: { name: 'tlm_consult_med', step: 1, level: 30 },
    },
    {
        requireNavebar: false,
        requireParamsTask: true,
        requireInProgress: true,
        googleAnalytics: true,
        path: '/telemed/consult/service/in-call',
        component: InCallAppointment,
        service: SERVICE_CONFIG.telemed,
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telemed/consult/service/pending-med',
        component: MedPendingTLM,
        service: SERVICE_CONFIG.telemedConsult,
        progress: { name: 'tlm_consult_med', step: 1, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telemed/consult/service/request-med',
        component: MedRequestTLM,
        service: SERVICE_CONFIG.telemedFulfillment,
        progress: { name: 'tlm_consult_med', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telemed/fulfillment/service/pending-med',
        component: MedPendingTMF,
        service: SERVICE_CONFIG.telemedFulfillment,
        progress: { name: 'tlm_consult_med', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        googleAnalytics: true,
        path: '/telemed/fulfillment/service/pharmacist-cancel',
        component: MedPendingPharmacistCancel,
        service: SERVICE_CONFIG.telemedFulfillment,
        progress: { name: 'tlm_consult_med', step: 6, level: 20 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telemed/fulfillment/service/confirm-med/address',
        component: SelectAddressTLP,
        service: SERVICE_CONFIG.telemedFulfillment,
        progress: { name: 'tlm_consult_med', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telemed/fulfillment/service/confirm-med/location',
        component: Location,
        service: SERVICE_CONFIG.telemedFulfillment,
        progress: { name: 'tlm_consult_med', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telemed/fulfillment/service/confirm-med',
        component: MedConfirm,
        service: SERVICE_CONFIG.telemedFulfillment,
        progress: { name: 'tlm_consult_med', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telemed/fulfillment/service/reject-med',
        component: MedReject,
        service: SERVICE_CONFIG.telemedFulfillment,
        progress: { name: 'tlm_consult_med', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        googleAnalytics: true,
        path: '/telemed/consult/service/srv-finish',
        component: SrvMedFinish,
        service: SERVICE_CONFIG.telemedConsult,
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telemed/fulfillment/service/srvmed-order',
        component: SrvMedOrder,
        service: SERVICE_CONFIG.telemedFulfillment,
        progress: { name: 'tlm_consult_med', step: 4, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        googleAnalytics: true,
        path: '/telemed/fulfillment/service/srv-finish',
        component: SrvFinish,
        service: SERVICE_CONFIG.telemedFulfillment,
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/telemed/fulfillment/service/srvmed-finish',
        component: SrvMedFinish,
        service: SERVICE_CONFIG.telemedFulfillment,
        progress: { name: 'tlm_consult_med', step: 6, level: 100 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        googleAnalytics: true,
        isLoadPage: true,
        path: '/telemed/fulfillment/address',
        component: SelectAddressTLM,
        service: SERVICE_CONFIG.telemedFulfillment,
        progress: { name: 'tlm_consult_med', step: 2, level: 50 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        googleAnalytics: true,
        path: '/telemed/fulfillment/location',
        component: Location,
        service: SERVICE_CONFIG.telemedFulfillment,
        progress: { name: 'tlm_consult_med', step: 2, level: 50 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireRemoteConfig: true,
        requireInProgress: true,
        isLoadPage: true,
        path: '/telemed/fulfillment/choose-pharmacy-store',
        component: ChoosePharmacyStore,
        pharmcareProgram: ['GATE_WAY'],
        service: SERVICE_CONFIG.telemedFulfillment,
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        requireRemoteConfig: true,
        isLoadPage: true,
        path: '/telemed/fulfillment/choose-pharmacy-favourite',
        component: ChoosePharmacyFavourite,
        service: SERVICE_CONFIG.telemedFulfillment,
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        path: '/telemed/fulfillment/service/pickup',
        component: Pickup,
        service: SERVICE_CONFIG.telemedFulfillment,
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        path: '/telemed/fulfillment/service/courier-status',
        component: CourierStatus,
        service: SERVICE_CONFIG.telemedFulfillment,
        progress: { name: 'tlm_consult_med', step: 4, level: 90 },
    },
];
