import React from 'react';
import { SERVICE_CONFIG } from 'constants/service-config';
import ClaimSelectV2 from 'pages/task/claim/ClaimSelectV2';
import ClaimPidV2 from 'pages/task/claim/ClaimPidV2';
import ClaimNotFoundV2 from 'pages/task/claim/ClaimNotFoundV2';
import ClaimStartV2 from 'pages/task/claim/ClaimStartV2';

// claim

// screening
const ScreeningFinal = React.lazy(() => import('pages/task/screening').then((module) => ({ default: module.ScreeningFinal })));
// phr existing
const Existing = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.Existing })));
const ExistingPersonalInfo = React.lazy(() =>
    import('pages/task/phr-existing').then((module) => ({ default: module.ExistingPersonalInfo }))
);
const ExistingHealthInfo = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.ExistingHealthInfo })));
const ExistingAdditionalInfo = React.lazy(() =>
    import('pages/task/phr-existing').then((module) => ({ default: module.ExistingAdditionalInfo }))
);
const ExistingLab = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.ExistingLab })));
const ExistingLabDetail = React.lazy(() => import('pages/task/phr-existing').then((module) => ({ default: module.ExistingLabDetail })));

// phr new
const PhrNewWhatDisease = React.lazy(() => import('pages/task/phr-new/phr-new-disease'));
const PhrNewDrugAllergy = React.lazy(() => import('pages/task/phr-new/phr-new-drug-allergy'));
const PhrNewDate = React.lazy(() => import('pages/task/phr-new/phr-new-date'));
const PhrNewGender = React.lazy(() => import('pages/task/phr-new/phr-new-gender'));
const PhrNewBody = React.lazy(() => import('pages/task/phr-new/phr-new-body'));
const PhrNewAdditional = React.lazy(() => import('pages/task/phr-new/phr-new-additional'));
// service srv
const SrvMedOrder = React.lazy(() => import('pages/task/srvmed-order/srvmed-order'));
const SrvMedFinish = React.lazy(() => import('pages/task/srvmed-finish/srvmed-finish'));
// choose pharmacy
const ChoosePharmacyFavourite = React.lazy(() =>
    import('pages/task/choose-pharmacy').then((module) => ({ default: module.ChoosePharmacyFavourite }))
);
// pickup
const Pickup = React.lazy(() => import('pages/task/pickup').then((module) => ({ default: module.Pickup })));
//select-hsp
const SelectHsp = React.lazy(() => import('pages/task/select-hsp'));
const SelectDisease = React.lazy(() => import('pages/task/select-disease'));

const ChoosePharmacyStore = React.lazy(() =>
    import('pages/task/choose-pharmacy').then((module) => ({ default: module.ChoosePharmacyStore }))
);
// select address
const SelectAddressTLP = React.lazy(() => import('pages/task/select-address').then((module) => ({ default: module.SelectAddressTLP })));
const SelectAddressTLM = React.lazy(() => import('pages/task/select-address').then((module) => ({ default: module.SelectAddressTLM })));
const Location = React.lazy(() => import('pages/task/location/location'));
// med
const MedPendingTLM = React.lazy(() => import('pages/task/pending-med/med-pending-tlm'));
const MedPendingTMF = React.lazy(() => import('pages/task/pending-med/med-pending-tmf'));
const MedRequestTLM = React.lazy(() => import('pages/task/request-med/med-request-tlm'));
const MedConfirm = React.lazy(() => import('pages/task/confirm-med/med-confirm'));
const MedReject = React.lazy(() => import('pages/task/reject-med/med-reject'));
const MedPendingPharmacistCancel = React.lazy(() => import('pages/task/pending-med/med-pending-pharmacist-cancel'));
// call
const JoinCallOndemand = React.lazy(() => import('pages/task/join-call/join-call-ondemand'));
const InCallTLPOndemand = React.lazy(() => import('pages/task/in-call/in-call-tlp-ondemand'));
const AppointmentReject = React.lazy(() => import('pages/task/appointment').then((module) => ({ default: module.AppointmentReject })));
const NoPayment = React.lazy(() => import('pages/task/no-payment/no-payment'));
const Payment = React.lazy(() => import('pages/task/payment/payment'));
const MobileBanking = React.lazy(() => import('pages/task/payment/mobile-banking'));
const CallExpired = React.lazy(() => import('pages/task/call-expired/call-expired'));
//courier-status
const CourierStatus = React.lazy(() => import('pages/task/courier-status'));

const ThaiIdCard = React.lazy(() => import('pages/task/profile-id-card/thai-id-card'));
const ProfileFace = React.lazy(() => import('pages/task/profile-face/profile-face'));
const AskMed = React.lazy(() => import('pages/task/ask-med'));
const RejectTaskTLM = React.lazy(() => import('pages/task/reject-task/reject-task-tlm'));
const RecreateTask = React.lazy(() => import('pages/task/recreate-task'));
const ExistingPharmacistNoteList = React.lazy(() => import('pages/task/phr-existing/existing-pharmacist-note-list'));
const ExistingPharmacistNoteDetail = React.lazy(() => import('pages/task/phr-existing/existing-pharmacist-note-detail'));
const LabConfirm = React.lazy(() => import('pages/task/confirm-lab'));
const SelectLabAddress = React.lazy(() => import('pages/task/SelectLabAddress'));
export const TlmNcdService = [
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/tlm-ncd/claim/select',
        component: ClaimSelectV2,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 1, level: 60 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        requireRemoteConfig: true,
        path: '/tlm-ncd/claim/pid',
        component: ClaimPidV2,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 1, level: 30 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        requireRemoteConfig: true,
        path: '/tlm-ncd/claim/profile/id-card',
        component: ThaiIdCard,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 1, level: 30 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        requireRemoteConfig: true,
        path: '/tlm-ncd/claim/profile/face',
        component: ProfileFace,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 1, level: 30 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/tlm-ncd/claim/not-found',
        component: ClaimNotFoundV2,
        service: SERVICE_CONFIG.tlmNcd,
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        requireRemoteConfig: true,
        requireMobileLanguage: true,
        exact: true,
        isLoadPage: true,
        path: '/tlm-ncd/claim',
        component: ClaimStartV2,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 1, level: 10 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        requireRemoteConfig: true,
        exact: true,
        isLoadPage: true,
        path: '/tlm-ncd/select-hsp',
        component: SelectHsp,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 1, level: 60 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        requireRemoteConfig: true,
        exact: true,
        isLoadPage: true,
        path: '/tlm-ncd/select-disease',
        component: SelectDisease,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 1, level: 60 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/tlm-ncd/phr/existing',
        component: Existing,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/tlm-ncd/phr/existing/personal-info',
        component: ExistingPersonalInfo,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/tlm-ncd/phr/existing/health-info',
        component: ExistingHealthInfo,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/tlm-ncd/phr/existing/additional-info',
        component: ExistingAdditionalInfo,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/tlm-ncd/phr/existing/lab/:detail',
        component: ExistingLabDetail,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 1, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/tlm-ncd/phr/existing/lab',
        component: ExistingLab,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 1, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/tlm-ncd/phr/existing/pharmacist-note/list/:cpaType',
        component: ExistingPharmacistNoteList,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        isLoadPage: true,
        path: '/tlm-ncd/phr/existing/pharmacist-note/detail/:detailType/:cpaType/:cpaTID',
        component: ExistingPharmacistNoteDetail,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/tlm-ncd/phr/new/disease',
        component: PhrNewWhatDisease,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 2, level: 0 },
    },

    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/tlm-ncd/phr/new/drug-allergy',
        component: PhrNewDrugAllergy,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 2, level: 10 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/tlm-ncd/phr/new/dob',
        component: PhrNewDate,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 2, level: 15 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/tlm-ncd/phr/new/gender',
        component: PhrNewGender,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 2, level: 20 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/tlm-ncd/phr/new/body',
        component: PhrNewBody,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 2, level: 25 },
    },
    {
        requireNavebar: true,
        requireParamsTask: false,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/tlm-ncd/phr/new/additional',
        component: PhrNewAdditional,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 2, level: 30 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        path: '/tlm-ncd/screening/final',
        component: ScreeningFinal,
        service: SERVICE_CONFIG.tlmNcd,
        progress: { name: 'tlm08', step: 2, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        requireRemoteConfig: true,
        exact: true,
        isLoadPage: true,
        path: '/tlm-ncd/consult/service/payment',
        component: Payment,
        service: SERVICE_CONFIG.tlmNcdConsult,
        progress: { name: 'tlm08', step: 3, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        requireRemoteConfig: true,
        isLoadPage: true,
        path: '/tlm-ncd/consult/service/payment/mobile',
        component: MobileBanking,
        service: SERVICE_CONFIG.tlmNcdConsult,
        progress: { name: 'tlm08', step: 3, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/tlm-ncd/consult/service/no-payment',
        component: NoPayment,
        service: SERVICE_CONFIG.tlmNcdConsult,
        progress: { name: 'tlm08', step: 3, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        path: '/tlm-ncd/consult/service/join-call',
        component: JoinCallOndemand,
        service: SERVICE_CONFIG.tlmNcdConsult,
        progress: { name: 'tlm08', step: 4, level: 20 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        path: '/tlm-ncd/consult/service/appointment-reject',
        component: AppointmentReject,
        service: SERVICE_CONFIG.tlmNcdConsult,
    },
    {
        requireNavebar: false,
        requireParamsTask: true,
        isLoadPage: false,
        path: '/tlm-ncd/consult/service/call-expired',
        component: CallExpired,
        service: SERVICE_CONFIG.tlmNcdConsult,
        progress: { name: 'tlm08', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: false,
        path: '/tlm-ncd/consult/service/task-reject',
        component: RejectTaskTLM,
        service: SERVICE_CONFIG.tlmNcdConsult,
        progress: { name: 'tlm08', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        path: '/tlm-ncd/consult/service/recreate-task',
        component: RecreateTask,
        service: SERVICE_CONFIG.tlmNcdConsult,
        progress: { name: 'tlm08', step: 3, level: 90 },
    },
    {
        requireNavebar: false,
        requireParamsTask: true,
        requireInProgress: true,
        path: '/tlm-ncd/consult/service/in-call',
        component: InCallTLPOndemand,
        service: SERVICE_CONFIG.tlmNcdConsult,
    },

    {
        requireNavebar: true,
        requireParamsTask: true,
        googleAnalytics: true,
        path: '/tlm-ncd/fulfillment/service/pharmacist-cancel',
        component: MedPendingPharmacistCancel,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/tlm-ncd/consult/service/pending-med',
        component: MedPendingTLM,
        service: SERVICE_CONFIG.tlmNcdConsult,
        progress: { name: 'tlm_consult_med', step: 1, level: 40 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/tlm-ncd/consult/service/ask-med',
        component: AskMed,
        service: SERVICE_CONFIG.tlmNcdConsult,
        progress: { name: 'tlm_consult_med', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/tlm-ncd/consult/service/request-med',
        component: MedRequestTLM,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 2, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        googleAnalytics: true,
        isLoadPage: true,
        path: '/tlm-ncd/fulfillment/address',
        component: SelectAddressTLM,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 2, level: 50 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        googleAnalytics: true,
        path: '/tlm-ncd/fulfillment/location',
        component: Location,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 2, level: 50 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/tlm-ncd/fulfillment/service/pending-med',
        component: MedPendingTMF,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        requireRemoteConfig: true,
        isLoadPage: true,
        path: '/tlm-ncd/fulfillment/choose-pharmacy-store',
        component: ChoosePharmacyStore,
        pharmcareProgram: ['GATE_WAY'],
        service: SERVICE_CONFIG.tlmNcdFulfillment,
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        requireRemoteConfig: true,
        isLoadPage: true,
        path: '/tlm-ncd/fulfillment/choose-pharmacy-favourite',
        servicePath: '/tlm-ncd/fulfillment',
        component: ChoosePharmacyFavourite,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/tlm-ncd/fulfillment/service/confirm-med',
        component: MedConfirm,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/tlm-ncd/fulfillment/service/confirm-lab',
        component: LabConfirm,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/tlm-ncd/fulfillment/service/reject-med',
        component: MedReject,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        // isLoadPage: true,
        googleAnalytics: true,
        path: '/tlm-ncd/fulfillment/service/confirm-lab/address',
        component: SelectLabAddress,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/tlm-ncd/fulfillment/service/confirm-med/address',
        component: SelectAddressTLP,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/tlm-ncd/fulfillment/service/confirm-med/location',
        component: Location,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 3, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        requireRemoteConfig: true,
        exact: true,
        isLoadPage: true,
        path: '/tlm-ncd/fulfillment/service/payment',
        component: Payment,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 5, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        requireRemoteConfig: true,
        isLoadPage: true,
        path: '/tlm-ncd/fulfillment/service/payment/mobile',
        component: MobileBanking,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 5, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        requireInProgress: true,
        exact: true,
        isLoadPage: true,
        path: '/tlm-ncd/fulfillment/service/no-payment',
        component: NoPayment,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 5, level: 0 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/tlm-ncd/fulfillment/service/srvmed-order',
        component: SrvMedOrder,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 4, level: 90 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        isLoadPage: true,
        googleAnalytics: true,
        path: '/tlm-ncd/fulfillment/service/srvmed-finish',
        component: SrvMedFinish,
        servicePath: '/tlm-ncd/fulfillment',
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 4, level: 100 },
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        path: '/tlm-ncd/fulfillment/service/pickup',
        component: Pickup,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
    },
    {
        requireNavebar: true,
        requireParamsTask: true,
        path: '/tlm-ncd/fulfillment/service/courier-status',
        component: CourierStatus,
        service: SERVICE_CONFIG.tlmNcdFulfillment,
        progress: { name: 'tlm_consult_med', step: 4, level: 90 },
    },
];
