import { POST } from 'api';
import { getApiCallTypeWithTheme, getSelectCoverage } from 'api/api-often';
import Breadcrumbs from 'components/common/breadcrumbs';
import EXPPopupJs from 'components/common/exp-popupjs';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import ProgressBar from 'components/common/progress-bar';
import { TextTitle } from 'components/common/styled-components-common';
import CoverageCard from 'components/elements/claim/coverage-card';
import { FormInput, FormPanel, Section } from 'components/layouts/page-layout';
import { TASK_REJECT_TASK_STATUS_CODE_LIST } from 'constants/default-config';
import { DEFAULT_PHARMCARE_PROGRAM } from 'constants/service-config';
import useAuth from 'hooks/useAuth';
import usePageContext from 'hooks/usePageContext';
import useQueryParams from 'hooks/useQueryParams';
import useServiceConfig from 'hooks/useServiceConfig';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import ClaimCancelTask from 'modules/conditional/claim-cancel-task';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { ClaimCheckCoveragePayload, TaskAlreadyDetail } from 'types';

function ClaimSelectV2() {
    const { t } = useTranslation(['claim']);
    const { profile, getProfile } = useAuth();
    const { setPageLoaded } = usePageContext();
    const navigate = useNavigate();
    const serviceConfig = useServiceConfig();
    const theme = useThemeWithPartner();
    const { query: params, queryToParams } = useQueryParams();
    //
    const [taskAlreadyDetail, setTaskAlreadyDetail] = useState<TaskAlreadyDetail>({
        taskId: '',
        processCode: 0,
    });
    const [coverageList, setCoverageList] = useState<any[]>([]);
    const [companyCode, setCompanyCode] = useState<string | null>('');
    const [showCancel, setShowCancel] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [coverageData, setCoverageData] = useState<{ phrExistFlag: number; skipPhr?: number; companyCode: string | null }>({
        phrExistFlag: 0,
        skipPhr: undefined,
        companyCode: null,
    });
    //

    const onCheckBTNCancel = (data: any = {}) => {
        const pvtParams = params?.pvt ? `&pvt=${params?.pvt}` : '';
        const redirectUrl = data.ctaRedirectUrl + '?task=' + data.taskId + '&flag=' + data?.task?.phrExistFlag + pvtParams;

        if (data.taskStatusCode < 10 && data.ppointmentNo === null && data.pharmacyServiceId) {
            return [
                {
                    label: t('claim:cancel_task.complete_ongoing_service'),
                    variant: 'primary',
                    action: () => navigate(redirectUrl),
                },
                {
                    label: t('claim:cancel_task.cancel_ongoing_service'),
                    className: 'btn-underline',
                    action: () => setShowCancel(false),
                },
            ];
        } else {
            return [
                {
                    label: t('claim:cancel_task.complete_ongoing_service'),
                    variant: 'primary',
                    action: () => navigate(redirectUrl),
                },
            ];
        }
    };
    const onCheckCoverage = async (coverage: any) => {
        const userProfile = await getProfile();
        if (coverage.amount < 1 && userProfile?.userXPartner?.partnerDefaultVoucherCode) {
            if (coverage?.inWaitingPeriod1Flag === 1) {
                EXPPopupJs.alert({
                    icon: 'warning',
                    title: t('common:warning'),
                    message: coverage?.inWaitingPeriod1Msg,
                    classNames: { message: 'font-detail2' },
                    buttons: [
                        {
                            label: t('common:ok'),
                            variant: 'primary',
                            action: ({ close }) => {
                                init(true);
                                close();
                            },
                        },
                    ],
                });
            } else if (coverage.inWaitingPeriod2Flag === 1) {
                EXPPopupJs.alert({
                    icon: 'warning',
                    title: 'warning',
                    message: coverage?.inWaitingPeriod2Msg,
                    classNames: { message: 'font-detail2' },
                    buttons: [
                        {
                            label: t('common:ok'),
                            variant: 'primary',
                            action: ({ close }) => {
                                init(true);
                                close();
                            },
                        },
                    ],
                });
            } else if (coverage.claimNotAvailableFlag === 1) {
                EXPPopupJs.alert({
                    icon: 'warning',
                    title: 'warning',
                    message: coverage?.claimNotAvailableMsg,
                    classNames: { message: 'font-detail2' },
                    buttons: [
                        {
                            label: t('common:ok'),
                            variant: 'primary',
                            action: ({ close }) => {
                                init(true);
                                close();
                            },
                        },
                    ],
                });
            }
        } else {
            onSelectCoverage(coverage);
        }
    };

    const onSelectCoverage = async (coverage: any) => {
        const { pharmacyCode, ltcFlag, hspCode, pvt } = params;
        setPageLoaded(false);

        try {
            const userProfile = await getProfile();
            const res = await getSelectCoverage({
                userPid: userProfile.userPid,
                pharmacyCode: pharmacyCode,
                hspCode: hspCode,
                pvt: pvt,
                createdAt: userProfile.createdAt,
                skipPhr: coverageData?.skipPhr,
                phrExistFlag: coverageData.phrExistFlag,
                serviceConfig: ltcFlag === 1 ? { ...serviceConfig, name: 'telepharmacyLTC' } : serviceConfig,
                coverageData: coverage,
                translation: t,
            });
            if (res.statusCode === 300) {
                setPageLoaded(true);
                setTaskAlreadyDetail({ ...res.taskAlready });

                EXPPopupJs.alert({
                    icon: 'warning',
                    title: t('common:warning'),
                    message: res.taskAlready.rejectReason,
                    classNames: { message: 'font-detail2' },
                    buttons: onCheckBTNCancel(res.taskAlready),
                });
            } else if (coverage?.inWaitingPeriod1Flag === 1) {
                setPageLoaded(true);
                EXPPopupJs.alert({
                    icon: 'warning',
                    title: t('common:warning'),
                    message: coverage?.inWaitingPeriod1Msg,
                    classNames: { message: 'font-detail2' },
                    buttons: [
                        {
                            label: t('common:ok'),
                            variant: 'primary',
                            action: () => navigate(res.redirectUrl),
                        },
                    ],
                });
            } else if (coverage.inWaitingPeriod2Flag === 1) {
                setPageLoaded(true);
                EXPPopupJs.alert({
                    icon: 'warning',
                    title: 'warning',
                    message: coverage?.inWaitingPeriod2Msg,
                    classNames: { message: 'font-detail2' },
                    buttons: [
                        {
                            label: t('common:ok'),
                            variant: 'primary',
                            action: () => navigate(res.redirectUrl),
                        },
                    ],
                });
            } else if (coverage.claimNotAvailableFlag === 1) {
                setPageLoaded(true);
                EXPPopupJs.alert({
                    icon: 'warning',
                    title: 'warning',
                    message: coverage?.claimNotAvailableMsg,
                    classNames: { message: 'font-detail2' },
                    buttons: [
                        {
                            label: t('common:ok'),
                            variant: 'primary',
                            action: () => navigate(res.redirectUrl),
                        },
                    ],
                });
            } else {
                navigate(res.redirectUrl);
            }
        } catch (error) {
            console.error('/claim/selectCoverage error', error);
            setPageLoaded(true);
        }
    };

    const onCancel = async (res: any) => {
        setIsSending(false);
        setShowCancel(false);

        if (
            TASK_REJECT_TASK_STATUS_CODE_LIST.some((e) => e === res.data.task.taskStatusCode) &&
            serviceConfig.servicePath === '/telepharmacy'
        ) {
            navigate('/telepharmacy/service/task-reject?task=' + res.data.task.taskId);
        } else {
            navigate('/home');
        }
    };
    const init = async (changVoucherCode) => {
        const { companyCode, privilegeCardNo, voucher, policyNumber } = params;
        try {
            const apiCallType = getApiCallTypeWithTheme(theme.name, companyCode);
            const userProfile = await getProfile();

            let payload: ClaimCheckCoveragePayload = {
                privilegeCardNo: privilegeCardNo,
                companyCode: companyCode || null,
                policyNumber: userProfile.policyNumber || policyNumber,
                voucherType: null,
                voucherCode: changVoucherCode ? userProfile?.userXPartner?.partnerDefaultVoucherCode : voucher ? voucher : null,
                pharmcareProgram: DEFAULT_PHARMCARE_PROGRAM[serviceConfig.name],
                apiCallType: changVoucherCode ? 'INTERNAL_DB' : apiCallType,
                validatePid: 0,
                taskCommunicationType: serviceConfig.communicationType,
                data: {
                    firstName: userProfile.firstName,
                    lastName: userProfile.lastName,
                    userPidType: userProfile.userPidType,
                    userPid: userProfile.userPid,
                    userEmail: userProfile.userEmail,
                    dateOfBirth: userProfile.dateOfBirth,
                    phoneNumber: userProfile.phoneNumber,
                },
            };

            if (userProfile?.userXPartner && userProfile?.userXPartner?.partnerCompanyCode) {
                payload.companyCode = userProfile.userXPartner.partnerCompanyCode;
            }

            const coverage = await POST('/claim/checkCoverage', { ...payload });
            if (changVoucherCode) {
                onSelectCoverage(coverage.data[0]);
            } else {
                if (coverage.statusCode === 300) {
                    EXPPopupJs.alert({
                        type: 'warning',
                        icon: 'warning',
                        title: t('common:error'),
                        message: coverage.rejectReason,
                        classNames: { message: 'font-detail2' },
                        buttons: [
                            {
                                label: t('common:ok'),
                                variant: 'primary',
                                action: () => navigate('/service?tabs=inprogress'),
                            },
                        ],
                    });
                } else {
                    setCoverageData((prevState) => ({
                        ...prevState,
                        companyCode: payload.companyCode,
                        phrExistFlag: coverage.phrExistFlag as number,
                        skipPhr: coverage.claimModeConfig?.skipPhr as number,
                    }));
                    setCoverageList([...coverage.data]);
                    setPageLoaded(true);
                }
            }
        } catch (error: any) {
            console.error('error: ', error);
            setPageLoaded(true);

            if (error.redirectUrl) {
                navigate(error.redirectUrl);
            }
        }
    };
    useEffect(() => {
        if (serviceConfig.readyConfig) {
            init(false);
        }
    }, [serviceConfig]);

    return (
        <>
            <Breadcrumbs
                title={t('claim:breadcrumbs.patient_details')}
                icon={<IconConfigWithTheme pageName='claim_select' iconName='breadcrumbs' />}
                onBack={() => navigate(-1)}
            />
            <Section>
                <ProgressBar />
                <FormPanel>
                    <FormInput>
                        <TextTitle id='title'>{t('claim:select_coverage.please_select_your_coverage')}</TextTitle>
                        {coverageList.map((data, index) => (
                            <CoverageCard key={index} companyCode={companyCode} data={data} onSelectCoverage={onCheckCoverage as any} />
                        ))}
                    </FormInput>
                </FormPanel>
            </Section>
            <ClaimCancelTask
                open={showCancel}
                serviceOrigin={serviceConfig.serviceOrigin}
                taskDetail={taskAlreadyDetail}
                onSubmited={onCancel as any}
                onClose={() => setShowCancel(false)}
            />
        </>
    );
}

export default ClaimSelectV2;
