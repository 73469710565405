import React from 'react';
import { POST } from 'api';
import configThemeMock from 'pages/template/config-theme';
import useAuth from 'hooks/useAuth';
import EXPPopupJs from 'components/common/exp-popupjs';
import { PageLoading } from 'components/common/PageLoading';

export type ConfigThemeInfo = {
    color: any;
    components: any;
    font: any;
};

export type ServiceCardInfo = {
    serviceName: string;
    withParams?: boolean;
    redirectUrl: string;
    openExternalBrowser?: boolean;
    withPharmacyCode?: boolean;
};
export type LogoInfo = {
    alt?: string;
    en?: string;
    th?: string;
    title?: string;
};
export interface ConfigThemeContextProps {
    configThemeName: string;
    configTheme: ConfigThemeInfo;
    configLogo: LogoInfo;
    configServiceCard: ServiceCardInfo[];
}

export interface ConfigThemeProviderComponent {
    children: React.ReactNode;
}

const ConfigThemeContext = React.createContext<ConfigThemeContextProps>(null!);

const ConfigThemeConsumer = ConfigThemeContext.Consumer;

const ConfigThemeProvider = ({ children }: Readonly<ConfigThemeProviderComponent>) => {
    const { auth, profile } = useAuth();
    const [configThemeData, setConfigThemeData] = React.useState<ConfigThemeInfo>({
        color: {},
        components: {},
        font: {},
    });
    const [configLogoData, setConfigLogoData] = React.useState({});
    const [configServiceCardData, setConfigerviceCardData] = React.useState<ServiceCardInfo[]>([]);
    const [configThemeName, setConfigThemeName] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        const getCofigTheme = async () => {
            let _forcedTheme = 'default';

            if (window.location.origin.includes('fastcare.co')) {
                _forcedTheme = 'fastcare';
            } else if (window.location.origin.includes('yaphrom')) {
                _forcedTheme = 'yaphrom';
            }
            try {
                const res = await POST('/misc/ui-theme-config', {
                    token: auth.accessToken,
                    forcedTheme: _forcedTheme,
                });
                setConfigerviceCardData(res.theme.serviceCard);
                setConfigThemeData(res.theme.theme);
                setConfigLogoData(res.theme.logo);
                setConfigThemeName(res.theme.name);
                setTimeout(() => {
                    setIsLoading(false);
                }, 300);
            } catch (error: any) {
                setIsLoading(false);
                console.log('error: ', error);
                if (error?.isError) {
                    setConfigerviceCardData(configThemeMock.serviceCard);
                    setConfigThemeData(configThemeMock.theme);
                    setConfigLogoData(configThemeMock.logo);
                    setConfigThemeName(configThemeMock.name);
                } else {
                    console.error('/misc/ui-theme-config error', error);
                    setConfigerviceCardData(error?.theme?.serviceCard || configThemeMock.serviceCard);
                    setConfigThemeData(error?.theme?.theme || configThemeMock.theme);
                    setConfigLogoData(error?.theme?.logo || configThemeMock.logo);
                    setConfigThemeName(error?.theme?.name || configThemeMock.name);
                    if (error.statusCode === 404) {
                        EXPPopupJs.simple('warning', 'แจ้งเตือน', 'ไม่พบ config theme');
                    }
                }
            }
        };
        if (auth.status === 'FINISH') {
            setIsLoading(true);
            getCofigTheme();
        }
    }, [profile.theme, JSON.stringify(auth)]);

    const values: ConfigThemeContextProps = React.useMemo(() => {
        return {
            configThemeName: configThemeName,
            configTheme: configThemeData,
            configLogo: configLogoData,
            configServiceCard: configServiceCardData,
        };
    }, [configThemeName]);
    return (
        <ConfigThemeContext.Provider value={values}>
            <PageLoading type={'spinner'} active={isLoading} data-test-id='pageLoading' />
            {children}
        </ConfigThemeContext.Provider>
    );
};

export { ConfigThemeConsumer, ConfigThemeProvider };
export default ConfigThemeContext;
