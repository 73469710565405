import styled from '@emotion/styled';
import React from 'react';
import Webcam from 'react-webcam';
import Button from 'components/common/button';
import useWindowSize from 'hooks/useWindowSize';
import { breakpoint } from 'helpers';
import { DEFAULT_BREAKPOINTS_SIZE } from 'constants/default-config';
import { POST } from 'api';
import usePageContext from 'hooks/usePageContext';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { TextTitle } from 'components/common/styled-components-common';

// Aspect Ratio 16:9 (1.777777777777778) (FULL HD)
const videoConstraints = {
    width: { min: 1280, ideal: 1920, max: 1920 },
    height: { min: 720, ideal: 1080 },
    aspectRatio: 1.777777777777778,
    frameRate: { max: 30 },
    facingMode: 'user',
};
// Aspect Ratio 1:1 (1) (FULL HD)
const videoConstraintsMobile = {
    width: { min: 720, ideal: 1080, max: 1080 },
    height: { min: 720, ideal: 1080 },
    aspectRatio: 1,
    frameRate: { max: 30 },
    facingMode: 'user',
};

const Grid = styled.div`
    label: webcam-frame;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: inherit;
    min-height: inherit;
    padding-top: 24px;

    ${breakpoint('XS')} {
        padding-top: 0;
    }

    .camera-frame {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 500px;
        margin: auto;

        ${breakpoint('XS')} {
            width: 100%;
        }

        video {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .face-guideline-overlay {
            // 16:9 (1.777777777777778) => 500px / 1.777777777777778 = height
            --aspectRatio16_9: 1.777777777777778; // 16:9
            --aspectRatio3_4: 1.333333333333333; // 4:3
            --aspectRatio4_6: 1.5; // 4:6
            --height4_6: calc(500px / var(--aspectRatio16_9));
            --width4_6: calc(var(--height4_6) / var(--aspectRatio4_6));

            ${breakpoint('XL')} {
                --height4_6: 500px;
                --width4_6: calc(var(--height4_6) / var(--aspectRatio4_6));
            }

            ${breakpoint('XS')} {
                --height4_6: var(--body-width);
                --width4_6: calc(var(--height4_6) / var(--aspectRatio4_6));
            }

            position: absolute;
            top: calc(50% - (var(--height4_6) / 2));
            left: calc(50% - (var(--width4_6) / 2));
            width: var(--width4_6);
            height: var(--height4_6);
            z-index: 2;
        }
    }

    .image-frame {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        height: calc(500px / 1.333333333333333);
        padding: 4px;
        margin: auto;

        ${breakpoint('XL')} {
            width: 100%;
            height: 500px;
        }
        ${breakpoint('XS')} {
            height: var(--body-width);
        }
    }

    .camera-tools {
        width: 100%;
        padding: 24px;
        margin-top: 24px;
        background-color: #000000ae;

        .tools {
            display: flex;
            width: 100%;
            margin-top: auto;
            margin-left: auto;
            margin-right: auto;
            max-width: 500px;
        }

        ${breakpoint('XS')} {
            margin-top: 0;

            .tools {
                max-width: unset;
            }
        }
    }
`;
const GridRetake = styled.div`
    label: webcam-frame-retake;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: inherit;
    min-height: inherit;
    padding-top: 24px;
    .profile-header {
        display: none;
    }
    ${breakpoint('XS')} {
        padding-top: 0;
        position: fixed;
        top: var(--navbar-height);
        right: 0;
        left: 0;
        bottom: 0;
        background-color: #fff;
        .profile-header {
            display: block;
            margin-top: 1.5rem;
        }
    }

    .camera-frame {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 500px;
        margin: auto;

        ${breakpoint('XS')} {
            width: 100%;
        }

        video {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .face-guideline-overlay {
            // 16:9 (1.777777777777778) => 500px / 1.777777777777778 = height
            --aspectRatio16_9: 1.777777777777778; // 16:9
            --aspectRatio3_4: 1.333333333333333; // 4:3
            --aspectRatio4_6: 1.5; // 4:6
            --height4_6: calc(500px / var(--aspectRatio16_9));
            --width4_6: calc(var(--height4_6) / var(--aspectRatio4_6));

            ${breakpoint('XL')} {
                --height4_6: 500px;
                --width4_6: calc(var(--height4_6) / var(--aspectRatio4_6));
            }

            ${breakpoint('XS')} {
                --height4_6: var(--body-width);
                --width4_6: calc(var(--height4_6) / var(--aspectRatio4_6));
            }

            position: absolute;
            top: calc(50% - (var(--height4_6) / 2));
            left: calc(50% - (var(--width4_6) / 2));
            width: var(--width4_6);
            height: var(--height4_6);
            z-index: 2;
        }
    }

    .image-frame {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        height: calc(500px / 1.333333333333333);
        padding: 4px;
        margin: auto;

        ${breakpoint('XL')} {
            width: 100%;
            height: 500px;
        }
        ${breakpoint('XS')} {
            height: var(--body-width);
        }
    }

    .camera-tools {
        width: 100%;
        padding: 24px;
        margin-top: 24px;
        background-color: #000000ae;

        .tools {
            display: flex;
            width: 100%;
            margin-top: auto;
            margin-left: auto;
            margin-right: auto;
            max-width: 500px;
        }

        ${breakpoint('XS')} {
            margin-top: 0;
            background-color: #fff;

            .tools {
                max-width: unset;
            }
        }
    }
`;
/**
 *
 * @param {{
 * pid?: string|null
 * onImageSaved: (setLoading:any)=> void
 * }} props
 * @returns
 */
function CameraProfilePicture({ pid, onImageSaved = () => {} }) {
    const { width } = useWindowSize();
    const { profile } = useAuth();
    const pageProps = usePageContext();
    const { t } = useTranslation(['claim']);
    //  ref
    const webcamRef = React.useRef(null);
    // state
    const [imageSrc, setImageSrc] = React.useState('loadData...');
    const [isLoading, setIsLoading] = React.useState(false);
    const [alreadyUserImage, setAlreadyUserImage] = React.useState(false);

    // method
    const onUseThisPicture = async () => {
        if (alreadyUserImage) return onImageSaved(setIsLoading);

        setIsLoading(true);
        try {
            await POST('/user/saveImage/face', {
                pid: pid ?? profile.userPid,
                data: imageSrc,
            });

            onImageSaved(setIsLoading);
        } catch (error) {
            console.error('/user/saveImage/face error', error);
            setIsLoading(false);
        }
    };

    const onCapture = React.useCallback(() => {
        const _imageSrc = getScreenshot();

        setImageSrc(_imageSrc);
    }, [webcamRef]);

    const getScreenshot = () => {
        const { state, props } = webcamRef.current;

        if (!state.hasUserMedia) return null;

        const canvas = getCanvas();
        // return canvas && canvas.toDataURL(props.screenshotFormat, props.screenshotQuality);
        return canvas && canvas.toDataURL('image/jpeg', 0.7);
    };

    const getCanvas = () => {
        const { state } = webcamRef.current;
        const aspectRatio = 1.5; // 4:6

        if (!webcamRef.current.video) return null;

        if (!state.hasUserMedia || !webcamRef.current.video.videoHeight) return null;

        if (!webcamRef.current.ctx) {
            const canvasHeight = webcamRef.current.video.videoHeight;
            const canvasWidth = canvasHeight / aspectRatio; // convert to 4:6

            webcamRef.current.canvas = document.createElement('canvas');
            webcamRef.current.canvas.width = canvasWidth;
            webcamRef.current.canvas.height = canvasHeight;
            webcamRef.current.ctx = webcamRef.current.canvas.getContext('2d');
        }

        const { ctx, canvas, props } = webcamRef.current;

        if (ctx && canvas) {
            let guideHeight = webcamRef.current.video.clientHeight;
            let guideWidth = guideHeight / aspectRatio;

            const sWidth = parseInt((guideWidth * webcamRef.current.video.videoWidth) / webcamRef.current.video.clientWidth);
            const sHeight = parseInt(sWidth * aspectRatio);

            const sx = parseInt(webcamRef.current.video.videoWidth / 2 - sWidth / 2);
            const sy = parseInt(webcamRef.current.video.videoHeight / 2 - sHeight / 2);

            // mirror the screenshot
            if (props.mirrored) {
                ctx.translate(canvas.width, 0);
                ctx.scale(-1, 1);
            }

            // drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
            ctx.drawImage(
                webcamRef.current.video,
                sx,
                sy,
                sWidth,
                sHeight,
                0,
                0,
                webcamRef.current.video.videoHeight / aspectRatio,
                webcamRef.current.video.videoHeight
            );
        }

        return canvas;
    };

    React.useEffect(() => {
        const checkUserIdCardImage = async () => {
            try {
                const res = await POST('/user/getImage/face', { pid: pid ?? profile.userPid });

                if (res.data) {
                    setImageSrc(res.data);
                    setAlreadyUserImage(true);
                } else {
                    setImageSrc('');
                }
                pageProps.setPageLoaded(true);
            } catch (error) {
                console.error('/user/getImage/face error:', error);
                setImageSrc('');
                pageProps.setPageLoaded(true);
            }
        };

        checkUserIdCardImage();
    }, []);

    return (
        <>
            {imageSrc ? (
                <Grid>
                    <div className='image-frame'>{imageSrc !== 'loadData...' && <img src={imageSrc} />}</div>
                    <div className='camera-tools'>
                        <div className='tools'>
                            <Button
                                disabled={isLoading}
                                onClick={() => {
                                    setImageSrc('');
                                    setAlreadyUserImage(false);
                                }}
                            >
                                <IconConfigWithTheme pageName='camera_profile_picture' iconName='camera' />
                                &nbsp;{t('claim:pid.retake')}
                            </Button>
                            <Button
                                data-test-id='confirmCamera'
                                variant='primary'
                                loading={isLoading}
                                disabled={!imageSrc || imageSrc === 'loadData...'}
                                className='ml-auto'
                                onClick={onUseThisPicture}
                            >
                                {t('claim:pid.confirm_face')}
                            </Button>
                        </div>
                    </div>
                </Grid>
            ) : (
                <GridRetake>
                    <div className='profile-header'>
                        <TextTitle style={{ justifyContent: 'center' }} className='header-title'>
                            {t('claim:pid.capture_face_image')}
                        </TextTitle>
                    </div>
                    <div className='camera-frame'>
                        <div className='face-guideline-overlay'>
                            <img src='/images/common/face_guideline4_6.png' />
                        </div>

                        <Webcam
                            mirrored
                            ref={webcamRef}
                            audio={false}
                            screenshotQuality={1}
                            screenshotFormat='image/png'
                            videoConstraints={
                                width <= DEFAULT_BREAKPOINTS_SIZE['XL'] ? { ...videoConstraintsMobile } : { ...videoConstraints }
                            }
                        />
                    </div>

                    <div className='camera-tools'>
                        <div className='tools'>
                            <Button variant='primary' className='ml-auto' onClick={onCapture}>
                                <IconConfigWithTheme pageName='camera_profile_picture' iconName='camera' /> &nbsp;{' '}
                                {t('claim:pid.take_photo')}
                            </Button>
                        </div>
                    </div>
                </GridRetake>
            )}
        </>
    );
}

export default CameraProfilePicture;
