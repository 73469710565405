import React, { useState } from 'react';
import { POST } from 'api';
import { AUTH_PROVIDER_BYPASS_PATH } from 'constants/default-config';
import { setCookie, getCookie, hasCookie } from 'cookies-next';
import { clearUserLocalStorage } from 'helpers';

export type AuthInfo = {
    status: 'WAITING' | 'FINISH';
    isAuth: boolean;
    accessToken: string | null;
    userId: number | null;
    role: string | null;
};

export type ProfileData = {
    displayName: string;
    theme: string;
    userId: number;
    roleId: number;
    roleAuthority: string | null;
    uiMode: string | null;
    userName: string | null;
    policyNumber?: string;
    userType: string | null;
    userEmail: string | null;
    userActivationCode: string | null;
    userImgUrl: string | null;
    lineImgUrl: string | null;
    password: string | null;
    lineUserId: string | null;
    lineEmail: string | null;
    lineDisplay: string | null;
    lineState: string | null;
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
    phoneNumber: string | null;
    userPidType: string | null;
    userPid: string | null;
    userPidTempFlag: number;
    dateOfBirth: string | null;
    lineOaStatus: number;
    lineOaUrl: string | null;
    acceptTcFlag: number;
    languageEn: number;
    language: string | null;
    sessionId: string | null;
    status: number;
    createdAt: string | null;
    phrExistFlag: number;
    paymentMode: string | null;
    omiseBasicAuthenEnc: string | null;
    pendingTaskCounts: number;
    pendingTasks: string | null;
    fakePidFlag: number;
    userXPartner: any;
    userRichMenu: any;
    cookieConsent: number;
    userSsoProfile: any;
    defaultCoverage: any;
    partner?: any;
    genEcdFlag: number;
};
export interface AuthContextProps {
    auth: AuthInfo;
    profile: ProfileData;
    getProfile: (token?: string) => Promise<any>;
    updateProfile: (token?: string) => Promise<any>;
    userLogout: (isLogout?: boolean) => void;
}

export interface AuthProviderComponent {
    children: React.ReactNode;
}

const PROFILE: ProfileData = {
    displayName: ':(',
    theme: 'default',
    uiMode: '',
    userId: 0,
    roleId: 0,
    roleAuthority: null,
    userName: null,
    userType: null,
    userEmail: null,
    userActivationCode: null,
    userImgUrl: null,
    lineImgUrl: null,
    password: null,
    lineUserId: null,
    lineEmail: null,
    lineDisplay: null,
    lineState: null,
    firstName: null,
    middleName: null,
    lastName: null,
    phoneNumber: null,
    userPidType: null,
    userPid: null,
    userPidTempFlag: 0,
    dateOfBirth: null,
    lineOaStatus: 0,
    lineOaUrl: null,
    acceptTcFlag: 0,
    languageEn: 0,
    language: null,
    sessionId: null,
    status: 0,
    createdAt: null,
    phrExistFlag: 0,
    paymentMode: null,
    omiseBasicAuthenEnc: null,
    pendingTaskCounts: 0,
    pendingTasks: null,
    fakePidFlag: 0,
    userXPartner: null,
    userRichMenu: null,
    cookieConsent: 0,
    userSsoProfile: null,
    genEcdFlag: 0,
    defaultCoverage: null,
};

const AuthContext = React.createContext<AuthContextProps>(null!);

const AuthConsumer = AuthContext.Consumer;

const AuthProvider = ({ children }: Readonly<AuthProviderComponent>) => {
    const hasUserToken = hasCookie('userToken');
    //
    const [auth, setAuth] = useState<AuthInfo>({
        status: 'WAITING',
        isAuth: false,
        accessToken: null,
        userId: null,
        role: null,
    });
    const [profile, setProfile] = useState<ProfileData>(PROFILE);
    const [profileImage, setProfileImage] = useState<string>('');

    //
    const getDisplayName = (firstName = '', lastName = '', lineDisplay = '') => {
        if (firstName) return [firstName, lastName].join(' ');

        if (lineDisplay) return lineDisplay;

        return ':(';
    };

    const getProfileImage = async (token: string) => {
        const bearer = 'Bearer ' + token;
        try {
            const res = await POST('/user/profile/image/v2', {}, false, { headers: { Authorization: bearer } });

            setProfileImage(res.data);
        } catch (error) {
            console.warn('%c>> error:', 'background: #00f; color: #fff', error);
        }
    };

    const authen = (token: string = '') => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await POST('/user/validateToken', {
                    token: token,
                    excludeAddress: 1,
                });
                //
                const _displayName = getDisplayName(res.userProfile.firstName, res.userProfile.lastName, res.userProfile.lineDisplay);
                let _theme = 'default';

                if (res.userProfile.userXPartner) {
                    _theme = res.userProfile.userXPartner.partnerTheme;
                    // if (THEME_CONFIG_LIST.some((value) => value === res.userProfile.userXPartner?.partnerTheme)) {
                    //     _theme = res.userProfile.userXPartner.partnerTheme;
                    // } else {
                    //     _theme = 'no_config';
                    // }
                }

                setProfile({
                    ...profile,
                    displayName: _displayName,
                    theme: _theme,
                    userId: res.userProfile.userId,
                    roleId: res.userProfile.roleId,
                    roleAuthority: res.userProfile.roleAuthority,
                    userName: res.userProfile.userName,
                    userType: res.userProfile.userType,
                    userEmail: res.userProfile.userEmail,
                    userActivationCode: res.userProfile.userActivationCode,
                    userImgUrl: null,
                    lineImgUrl: res.userProfile.lineImgUrl,
                    password: res.userProfile.password,
                    lineUserId: res.userProfile.lineUserId,
                    lineEmail: res.userProfile.lineEmail,
                    lineDisplay: res.userProfile.lineDisplay,
                    lineState: res.userProfile.lineState,
                    firstName: res.userProfile.firstName,
                    middleName: res.userProfile.middleName,
                    lastName: res.userProfile.lastName,
                    phoneNumber: res.userProfile.phoneNumber,
                    userPidType: res.userProfile.userPidType,
                    userPid: res.userProfile.userPid,
                    userPidTempFlag: res.userProfile.userPidTempFlag,
                    dateOfBirth: res.userProfile.dateOfBirth,
                    lineOaStatus: res.userProfile.lineOaStatus,
                    lineOaUrl: res.userProfile.lineOaUrl,
                    acceptTcFlag: res.userProfile.acceptTcFlag,
                    languageEn: res.userProfile.languageEn,
                    language: res.userProfile.language,
                    sessionId: res.userProfile.sessionId,
                    status: res.userProfile.status,
                    createdAt: res.userProfile.createdAt,
                    phrExistFlag: res.userProfile.phrExistFlag,
                    paymentMode: res.userProfile.paymentMode,
                    omiseBasicAuthenEnc: res.userProfile.omiseBasicAuthenEnc,
                    pendingTaskCounts: res.userProfile.pendingTaskCounts,
                    pendingTasks: res.userProfile.pendingTasks,
                    fakePidFlag: res.userProfile.fakePidFlag,
                    userXPartner: res.userProfile.userXPartner,
                    userRichMenu: res.userProfile.userRichMenu,
                    cookieConsent: res.userProfile.cookieConsent,
                    userSsoProfile: res.userProfile.userSsoProfile,
                    uiMode: res.userProfile.uiMode,
                    genEcdFlag: res.userProfile.genEcdFlag,
                    defaultCoverage: {
                        voucherCode: res.userProfile.defaultCoverage?.voucherCode,
                    },
                });

                setAuth({
                    ...auth,
                    isAuth: true,
                    accessToken: token,
                    userId: res.userProfile.userId,
                    role: res.userProfile.roleAuthority,
                    status: 'FINISH',
                });

                const isVisited = window.localStorage.getItem('pharmcare.co');

                if (!isVisited) window.localStorage.setItem('pharmcare.co', '1');

                getProfileImage(token);
                resolve(res.userProfile);
            } catch (error) {
                console.warn('%c>> error:', 'background: #00f; color: #fff', error);
                removeProfile(false);
                reject(new Error(error as string));
            }
        });
    };

    const getUserProfile = (token: string = '') => {
        let _token: string = token;

        if (_token) {
            window.localStorage.setItem('_token_', _token);
            setCookie('userToken', _token, {
                maxAge: 3 * 24 * 60 * 60,
                domain: process.env.REACT_APP_DOMAIN,
                sameSite: 'none',
                secure: true,
            }); // 3 days
            setCookie('userToken', _token, {
                maxAge: 3 * 24 * 60 * 60,
                domain: process.env.REACT_APP_DOMAIN_YAPHROM,
                sameSite: 'none',
                secure: true,
            }); // 3 days
        } else {
            // _token = window.localStorage.getItem('_token_')
            _token = (getCookie('userToken', { domain: process.env.REACT_APP_DOMAIN }) as string) ?? '';
        }

        return authen(_token);
    };
    const setUserLogout = async () => {
        try {
            await POST('/user/logOut');
        } catch (error) {
            console.log('/user/logOut error:', error);
        }
    };
    const removeProfile = (isLogout = true) => {
        if (isLogout) setUserLogout();

        if (!isLogout) clearUserLocalStorage();
        setProfile({
            ...profile,
            ...PROFILE,
        });
        setAuth({
            ...auth,
            isAuth: false,
            userId: null,
            accessToken: null,
            role: 'PUBLIC',
            status: 'FINISH',
        });
    };

    React.useEffect(() => {
        const shouldCheckAuthenticate = async () => {
            const reToken = window.localStorage.getItem('_token_');
            const token = reToken ?? getCookie('userToken', { domain: process.env.REACT_APP_DOMAIN });

            // remove old version token
            if (reToken) {
                window.localStorage.removeItem('_token_');
                setCookie('userToken', token, { domain: process.env.REACT_APP_DOMAIN, sameSite: 'none', secure: true });
                setCookie('userToken', token, { domain: process.env.REACT_APP_DOMAIN_YAPHROM, sameSite: 'none', secure: true });
            }

            if (token && !auth.isAuth && AUTH_PROVIDER_BYPASS_PATH.every((path) => !window.location.pathname.startsWith(path))) {
                try {
                    await authen(token as string);
                } catch (error) {
                    console.error('check authenticate error', error);
                }
            } else {
                setAuth({
                    ...auth,
                    isAuth: false,
                    userId: null,
                    accessToken: null,
                    role: 'PUBLIC',
                    status: 'FINISH',
                });
            }
        };
        shouldCheckAuthenticate();
    }, []);

    React.useEffect(() => {
        const checkVisibleToken = async () => {
            if (!hasUserToken && auth.isAuth) {
                // removeProfile(false)
                try {
                    await POST('/misc/lost-token', {
                        token: auth.accessToken,
                        userProfile: profile,
                    });
                } catch (error) {
                    console.error('lost-token', error);
                }
                try {
                    await authen(auth.accessToken as string);
                } catch (error) {
                    console.error('check authenticate error', error);
                }
            }
        };
        checkVisibleToken();
    }, [hasUserToken]);

    const values: AuthContextProps = React.useMemo(() => {
        console.log('%c>> update authenticate', 'background: #48ca00; color: #fff');

        return {
            auth: auth,
            profile: { ...profile, userImgUrl: profileImage || profile.lineImgUrl },
            getProfile: getUserProfile,
            updateProfile: getUserProfile,
            userLogout: removeProfile,
        };
    }, [JSON.stringify(profile), JSON.stringify(auth), profileImage]);

    console.log('%c>> render authenticate', 'background: #1BE39A; color: #fff');
    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthConsumer, AuthProvider };
export default AuthContext;
