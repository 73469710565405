import React from 'react';
import { StepperItem } from './Stepper.styled';
import { StatusType } from '.';
import type { ItemsProps, ProgressDotRender } from './Stepper';

export interface StepperItemProps extends ItemsProps {
    status: StatusType;
    percent: number;
    step: number;
    width: number;
    progressDot?: boolean | ProgressDotRender;
}

const Dot = () => <div className='steps-item-dot-default' />;

function StepperItemComponent({
    step,
    title,
    description,
    icon,
    status,
    percent,
    width,
    disabled,
    progressDot = true,
    onClick,
}: Readonly<StepperItemProps>) {
    return (
        <StepperItem width={width} percent={percent} className={`steps-item steps-item-${status}`}>
            <div className='steps-item-container'>
                <div className='steps-item-tail'></div>
                <button
                    type='button'
                    className='steps-item-icon'
                    disabled={disabled}
                    onClick={onClick ? onClick : () => null}
                    style={{ cursor: onClick ? 'pointer' : 'default' }}
                >
                    {icon ? <div className='steps-item-icon-custom'>{icon}</div> : <div className='steps-item-icon-default' />}
                    {typeof progressDot === 'function' &&
                        !icon &&
                        progressDot(Dot, { index: step, status: status, title: title, description: description })}
                    {progressDot && typeof progressDot !== 'function' && !icon && <Dot />}
                </button>
                <div className='steps-item-content'>
                    <div className='steps-item-title'>{title}</div>
                    <div className='steps-item-description'>{description}</div>
                </div>
            </div>
        </StepperItem>
    );
}

export default StepperItemComponent;
